import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import RS, { components } from 'react-select';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import styles from './FormSelect.module.css';
import BoxLabel from '../../BoxLabel';
import { FieldErrorMessage } from '../FormErrorMessage';
import { FormGroup } from '../FormGroup';

type FormSelectProps = {
  name: string;
  options: any;
  disabled?: boolean;
  label?: React.ReactNode;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  position?: 'fixed' | 'absolute';
  errorobj: any;
  size?: FormComponentSize;
  value?: any;
  isVisible?: boolean;
  onChange?: (value: any) => void;
  isLoading?: boolean;
  formGroupClassName?: string;
  control: any;
  unregister: any;
};

type OptionType = {
  value: string;
  label: string;
  disabled?: boolean;
};

const sizeStyles: Record<FormComponentSize, string> = {
  xlarge: styles.sizeXlarge,
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator
    {...props}
    className='react-select__indicatorContainer'
  >
    <ArrowDown className='react-select__arrowDown' />
  </components.DropdownIndicator>
);

export const FormSelect: FC<FormSelectProps> = ({
  name,
  options,
  disabled,
  label,
  position = 'absolute',
  menuPlacement = 'auto',
  size = 'medium',
  isVisible = true,
  value: val,
  errorobj,
  onChange,
  isLoading,
  formGroupClassName,
  unregister,
  control,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible((prevValue) => {
      prevValue !== isVisible && !isVisible && unregister(name);
      return isVisible;
    });
  }, [isVisible]);

  const opts: OptionType[] = options;

  let isError = false;
  let errorMessage = '';
  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return visible ? (
    <Controller
      control={control}
      defaultValue={
        (options && options.find((c: any) => c.value === val)) ?? ''
      }
      name={name}
      render={({ field: { value, onChange: _onChange } }) => (
        <FormGroup className={classNames(formGroupClassName)}>
          <div
            className={classNames(
              styles.select,
              isError && styles.hasError,
              sizeStyles[size],
              isFocused && styles.focused,
              (disabled || (opts && opts.length === 1)) && styles.disabled,
            )}
          >
            {label && (
              <BoxLabel htmlFor={name} onTop={value || value === 0} size={size}>
                {label}
              </BoxLabel>
            )}
            <RS
              {...otherProps}
              className='react-select-container'
              classNamePrefix='react-select'
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator,
              }}
              inputId={name}
              isDisabled={disabled || opts?.length === 1}
              isLoading={isLoading}
              isOptionDisabled={(option) => option.disabled}
              isSearchable={false}
              menuPlacement={menuPlacement}
              menuPosition={position}
              name={name}
              onBlur={() => setIsFocused(false)}
              onChange={(prmVal) => {
                onChange && onChange(prmVal);
                _onChange(prmVal.value);
              }}
              onFocus={() => setIsFocused(true)}
              options={opts}
              placeholder={null}
              value={
                (options && options.find((c: any) => c.value === value)) ?? ''
              }
            />
          </div>

          {isError && <FieldErrorMessage error={errorMessage} />}
        </FormGroup>
      )}
    />
  ) : null;
};
