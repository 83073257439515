import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import InlineLabel from '../../InlineLabel';
import styles from './FormCheckbox.module.css';
import { FieldErrorMessage } from '../FormErrorMessage';
import { FormGroup } from '../FormGroup';

type Size = 'large' | 'medium' | 'small';

type FormCheckboxProps = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['input']>,
  {
    name: string;
    imageRadius?: boolean;
    label?: React.ReactNode;
    labelImage?: string;
    size?: Size;
    isVisible?: boolean;
    labelClassName?: string;
    defaultValue?: boolean;
    errorobj: any;
    unregister: any;
    register: any;
    getValues: any;
  }
>;

const sizeStyle: Record<Size, string> = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

export const FormCheckbox: FC<FormCheckboxProps> = ({
  className,
  name,
  imageRadius = true,
  labelImage,
  isVisible = true,
  labelClassName,
  label,
  size = 'medium',
  defaultValue = false,
  errorobj,
  unregister,
  register,
  getValues,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible((prevValue) => {
      prevValue !== isVisible && !isVisible && unregister(name);
      return isVisible;
    });
  }, [isVisible]);

  const value = getValues(name) || defaultValue;

  let isError = false;
  let errorMessage = '';
  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  return visible ? (
    <FormGroup>
      <label
        className={classNames(
          styles.checkbox,
          value && styles.isActive,
          isError && styles.hasError,
          value && 'accessChecked',
          className,
          sizeStyle[size],
        )}
        htmlFor={name}
      >
        <input
          {...register(name as string)}
          {...otherProps}
          className={styles.input}
          id={name}
          type='checkbox'
        />

        {label !== undefined && (
          <InlineLabel
            className={classNames(styles.inlineLabel, labelClassName)}
            image={labelImage}
            imageRadius={imageRadius}
            size={size}
          >
            {label}
          </InlineLabel>
        )}
      </label>
      {isError && <FieldErrorMessage error={errorMessage} />}
    </FormGroup>
  ) : null;
};
