import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import ActivityIndicator from '../ActivityIndicator';
import EmptyStateDisplay from '../EmptyStateDisplay';
import Select from '../Select';
import { ReactComponent as DuelsLost } from './duelsLost.svg';
import { ReactComponent as DuelsWon } from './duelsWon.svg';
import styles from './UserStats.module.css';
import { ReactComponent as WinRate } from './winRate.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    userId: number;
  }
>;

type GameOptionProps = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['option']>,
  {
    gameId: number;
  }
>;

const GameOption = ({ gameId }: GameOptionProps) => {
  const { data: game } = useSWR<Game>(`/games/${gameId}/`);

  return game === undefined ? null : <span>{game.name}</span>;
};

export default function UserStats({ className, userId, ...props }: Props) {
  const intl = useIntl();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<number | null>(null);
  const { data: userGamePlacements } = useSWR<UserGamePlacement[]>(
    `/games/user_game_placements/?user=${userId}`,
  );

  const userGamePlacement = useMemo<UserGamePlacement | null>(() => {
    if (userGamePlacements === undefined) return null;

    const gameDetails = userGamePlacements.find((gs) => gs.id === selectedGame);

    return gameDetails === undefined ? null : gameDetails;
  }, [selectedGame, userGamePlacements]);

  useEffect(() => {
    if (
      userGamePlacements !== undefined &&
      userGamePlacements.length > 0 &&
      selectedGame === null
    ) {
      setSelectedGame(userGamePlacements[0].id);
    }
  }, [selectedGame, userGamePlacements]);

  return userGamePlacements === undefined ? (
    <ActivityIndicator />
  ) : (
    <section {...props} className={classNames(styles.userStats, className)}>
      <div className={styles.header}>
        <h4>
          <FormattedMessage defaultMessage='User Stats' />
        </h4>
        <>
          {userGamePlacement === null ? null : (
            <Select
              disabled={isDisabled}
              id='sort-dropdown'
              label={intl.formatMessage({ defaultMessage: 'Game' })}
              name='selectedGame'
              onChange={(e) => {
                setIsDisabled(true);

                setSelectedGame(Number(e.value));

                setIsDisabled(false);
              }}
              options={userGamePlacements?.map((o: any) => ({
                label: <GameOption gameId={o.game} key={o.id} />,
                value: o.id,
              }))}
              size='small'
              value={selectedGame || ''}
            />
          )}
        </>
      </div>

      <div className={classNames(styles.body)}>
        {userGamePlacement === null ? (
          <EmptyStateDisplay
            kind='noUserStat'
            message={intl.formatMessage({ defaultMessage: 'No stats found' })}
          />
        ) : (
          <>
            <div className={styles.stat}>
              <DuelsWon />

              <p className={styles.statName}>
                <FormattedMessage defaultMessage='Duels Won' />
              </p>

              <p className={styles.statValue}>{userGamePlacement.winCount}</p>
            </div>

            <div className={styles.stat}>
              <DuelsLost />

              <p className={styles.statName}>
                <FormattedMessage defaultMessage='Duels Lost' />
              </p>

              <p className={styles.statValue}>{userGamePlacement.loseCount}</p>
            </div>

            <div className={styles.stat}>
              <WinRate />

              <p className={styles.statName}>
                <FormattedMessage defaultMessage='Win Rate' />
              </p>

              <p className={styles.statValue}>
                {userGamePlacement.winRate ? (
                  <FormattedMessage
                    defaultMessage='{number}%'
                    values={{ number: userGamePlacement.winRate }}
                  />
                ) : (
                  'N/A'
                )}
              </p>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
