import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import Container, { Props as ContainerProps } from '../Container';
import styles from './CallToAction.module.css';

export type Props = Overwrite<
  ContainerProps,
  {
    title: React.ReactNode;
    content: React.ReactNode;
    image?: any;
  }
>;

export default function CallToAction({
  className,
  title,
  content,
  image,
  ...props
}: Props) {
  const router = useRouter();
  return (
    <section {...props} className={classNames(styles.callToAction, className)}>
      <Container className={styles.container}>
        {image && <img alt='Gamer Arena' src={image} />}
        <h1 className={styles.title}>{title}</h1>

        <p className={styles.content}>{content}</p>

        <Link href={`${router.pathname}/?action=Hero`} passHref scroll={false}>
          <Button className={styles.button} size='large'>
            <FormattedMessage defaultMessage='Play Game' />
          </Button>
        </Link>
      </Container>
    </section>
  );
}
