import classNames from 'classnames';
import React from 'react';
import Link from 'next/link';
import styles from './LandingSignUpButton.module.css';
import { ReactComponent as Arrow } from './arrow.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['a']>,
  {
    content: any;
    href: any;
  }
>;

export default function LandingSignUpButton({
  className,
  content,
  href,
  ...props
}: Props) {
  return (
    <Link href={href} passHref scroll={false}>
      <a {...props} className={classNames(styles.signUpButton, className)}>
        {content}
        <Arrow />
      </a>
    </Link>
  );
}
