import classNames from 'classnames';
import React, { useContext } from 'react';
import SwiperCore, { Autoplay, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FormattedMessage } from 'react-intl';
import Container from '../Container';
import styles from './Partners.module.css';
import FiberGames from './fiber-games.png';
import TheSandbox from './the-sandbox.png';
import Amazon from './amazon.png';
import Papara from './papara.png';
import EnnoWallet from './enno-wallet.png';
import Krafton from './krafton.png';
import Icrypex from './Icrypex.png';
import GamingTurkey from './gaming-turkey.png';
import Atari from './atari.png';
import L8 from './L8.png';
import Pubg from './pubg.png';
import { LocalizationContext } from '../../contexts';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  className?: string;
  noTitle?: boolean;
  fullWidth?: boolean;
};

SwiperCore.use([Autoplay, Parallax]);

export default function Partners({
  className,
  noTitle = false,
  fullWidth = false,
  ...props
}: Props) {
  const { dir } = useContext(LocalizationContext);
  const swiperRef = React.useRef<SwiperCore>();

  const onSwiperInit = (Swip: SwiperCore): void => {
    swiperRef.current = Swip;
  };

  return (
    <section
      {...props}
      className={classNames(styles.partners, className)}
      data-cy='partners'
    >
      <Container className={classNames(fullWidth && styles.noPadding)}>
        {!noTitle && (
          <h3>
            <FormattedMessage defaultMessage='Partners' />
          </h3>
        )}

        <div className={styles.content}>
          <Swiper
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            centeredSlides
            className={classNames(styles.slider, fullWidth && styles.fullWidth)}
            dir={dir}
            grabCursor
            id='partners'
            loop
            onInit={onSwiperInit}
            slidesPerView='auto'
            spaceBetween={0}
            speed={3000}
            watchSlidesProgress
          >
            <>
              <SwiperSlide className={styles.slide} key='krafton'>
                <div className={styles.partner}>
                  <img
                    alt='Krafton'
                    className={classNames(styles.icon, styles.krafton)}
                    src={Krafton.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='theSandbox'>
                <div className={styles.partner}>
                  <img
                    alt='The Sandbox'
                    className={classNames(styles.icon, styles.theSandbox)}
                    src={TheSandbox.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='amazon'>
                <div className={styles.partner}>
                  <img
                    alt='Amazon'
                    className={classNames(styles.icon, styles.amazon)}
                    src={Amazon.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='atari'>
                <div className={styles.partner}>
                  <img
                    alt='Atari'
                    className={classNames(styles.icon, styles.atari)}
                    src={Atari.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='pubg'>
                <div className={styles.partner}>
                  <img
                    alt='Pubg'
                    className={classNames(styles.icon, styles.pubg)}
                    src={Pubg.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='fiberGames'>
                <div className={styles.partner}>
                  <img
                    alt='fiberGames'
                    className={classNames(styles.icon, styles.fiberGames)}
                    src={FiberGames.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='papara'>
                <div className={styles.partner}>
                  <img
                    alt='Papara'
                    className={classNames(styles.icon, styles.papara)}
                    src={Papara.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='ennoWallet'>
                <div className={styles.partner}>
                  <img
                    alt='EnnoWallet'
                    className={classNames(styles.icon, styles.ennoWallet)}
                    src={EnnoWallet.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='icrypex'>
                <div className={styles.partner}>
                  <img
                    alt='Icrypex'
                    className={classNames(styles.icon, styles.icrypex)}
                    src={Icrypex.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='gamingTurkey'>
                <div className={styles.partner}>
                  <img
                    alt='GamingTurkey'
                    className={classNames(styles.icon, styles.gamingTurkey)}
                    src={GamingTurkey.src}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className={styles.slide} key='l8'>
                <div className={styles.partner}>
                  <img
                    alt='L8'
                    className={classNames(styles.icon, styles.l8)}
                    src={L8.src}
                  />
                </div>
              </SwiperSlide>
            </>
          </Swiper>
        </div>
      </Container>
    </section>
  );
}
