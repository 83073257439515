import classNames from 'classnames';
import Link from 'next/link';
import React, { useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useClickAway } from 'react-use';
import { AuthAndApiContext, RegionContext } from '../../contexts';
import { sendGAEvent } from '../../helpers';
import BackgroundShade from '../BackgroundShade';
import Button from '../Button';
import GACoin from '../GACoin';
import HeaderButton from '../HeaderButton';
import { ReactComponent as Transactions } from './transactions.svg';
import { ReactComponent as Wallet } from './wallet.svg';
import styles from './WalletMenu.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  className?: string;
};

export default function WalletMenu({ className, ...props }: Props) {
  const {
    api,
    user,
    /* userBasicToken, */ /* deviceToken , */ setDeviceToken,
  } = useContext(AuthAndApiContext);
  const { region } = useContext(RegionContext);

  /*   const basicToken = userBasicToken?.slice(6); */

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const box = useRef<HTMLDivElement>(null);

  useClickAway(box, () => {
    if (isOpen) setIsOpen(false);
  });

  async function getDeviceToken() {
    const response = await api.get('/users/get_firebase_access_token/');
    const responseJson = await response.json();

    setDeviceToken(responseJson.token);
  }

  getDeviceToken();

  return user === null ? null : (
    <div {...props} className={classNames(className, styles.walletMenu)}>
      <BackgroundShade isVisible={isOpen} />
      <HeaderButton
        className={styles.balanceButton}
        onClick={() => {
          setIsOpen((o) => !o);
          sendGAEvent({
            category: 'Wallet Menu',
            event: 'ClickToTokenHeader',
            label: 'Wallet',
          });
        }}
      >
        <GACoin className={styles.gaCoin} />
        {user.balance}
      </HeaderButton>
      <TransitionGroup>
        {isOpen && (
          <CSSTransition timeout={300}>
            <div className={styles.box} ref={box}>
              <div className={styles.header}>
                <p className={styles.title}>
                  <FormattedMessage defaultMessage='My Wallet' />
                </p>
              </div>

              <div className={styles.body}>
                <div className={styles.balance}>
                  <div className={styles.cell}>
                    <GACoin className={styles.gaCoin} />

                    <div>
                      <p className={styles.cellTitle}>
                        <FormattedMessage defaultMessage='Total Tokens' />
                      </p>

                      <p
                        className={styles.cellValue}
                      >{`${user.balance} GA Coin`}</p>
                    </div>
                  </div>

                  <div className={styles.cell}>
                    <Wallet />

                    <div>
                      <p className={styles.cellTitle}>
                        <FormattedMessage defaultMessage='Cash Value' />
                      </p>

                      <p className={styles.cellValue}>
                        {`${parseFloat(
                          (user.balance * region.exchangeRate).toFixed(2),
                        )} ${region.currencyCode}`}
                      </p>
                    </div>
                  </div>
                </div>

                <hr className={styles.separator} />

                <div className={styles.actions}>
                  <div className={styles.actionWrapper}>
                    <Link href='/wallet/withdraw' passHref>
                      <Button
                        onClick={() => {
                          sendGAEvent({
                            category: 'Wallet Menu',
                            event: 'ClickToWithdraw',
                            label: 'Wallet',
                          });
                        }}
                        variant='secondary'
                      >
                        <FormattedMessage defaultMessage='Withdraw' />
                      </Button>
                    </Link>
                  </div>
                  <div className={styles.actionWrapper}>
                    <Link href='/wallet/deposit' passHref>
                      <Button
                        onClick={() => {
                          sendGAEvent({
                            category: 'Wallet Menu',
                            event: 'ClickToDeposit',
                            label: 'Wallet',
                          });
                        }}
                        variant='green'
                      >
                        <FormattedMessage defaultMessage='Deposit' />
                      </Button>
                    </Link>
                  </div>

                  {/*  <div
                    className={classNames(styles.actionWrapper, styles.cripto)}
                  >
                    <Link
                      href={`https://wallet.gamerarena.com/?token=${basicToken}&deviceToken=${deviceToken}`}
                      passHref
                    >
                      <a target='blank'>
                        <Button
                          className={styles.criptoButton}
                          onClick={() => {
                            sendGAEvent({
                              category: 'Wallet Menu',
                              event: 'ClickToCyrptoWallet',
                              label: 'Wallet',
                            });
                          }}
                          variant='secondary'
                        >
                          <FormattedMessage defaultMessage='Crypto Wallet Transactions' />
                        </Button>
                      </a>
                    </Link>
                  </div> */}
                </div>
              </div>

              <div className={styles.footer}>
                <Link href='/wallet'>
                  <a className={styles.footerButton}>
                    <Transactions />

                    <FormattedMessage defaultMessage='View all transactions' />
                  </a>
                </Link>
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}
