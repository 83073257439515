import classNames from 'classnames';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useClickAway } from 'react-use';
import { useRouter } from 'next/router';
import { useKeyDown } from '../../../hooks';
import BackgroundShade from '../../BackgroundShade';
import HeaderButton from '../../HeaderButton';
import styles from './LandingMenu.module.css';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as Teams } from './teams.svg';
import { ReactComponent as Faq } from './faq.svg';
// import { ReactComponent as Arrow } from './arrow.svg';
import LanguageSelector from '../../LanguageSelector';

type Devices = 'mobile' | 'desktop';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  className?: string;
  device: Devices;
};

export default function LandingMenu({ className, device, ...props }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const section = useRef<HTMLElement>(null);
  const router = useRouter();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [router.asPath]);

  const content = (
    <div className={styles.mobileContent}>
      <div className={styles.links}>
        <Link
          href={`${router.pathname}/?action=${
            router.pathname === '/' ? 'Tournaments' : 'Features'
          }`}
          passHref
          scroll={false}
        >
          <a className={styles.link}>
            {router.pathname === '/' ? (
              <FormattedMessage defaultMessage='Tournaments' />
            ) : (
              <FormattedMessage defaultMessage='Features' />
            )}
          </a>
        </Link>
        <Link
          href={`${router.pathname}/?action=Testimonials`}
          passHref
          scroll={false}
        >
          <a className={styles.link}>
            <FormattedMessage defaultMessage='Testimonials' />
          </a>
        </Link>
        <Link href={`${router.pathname}/?action=FAQ`} passHref scroll={false}>
          <a className={styles.link}>
            <FormattedMessage defaultMessage='FAQ' />
          </a>
        </Link>
        {/* <div className={styles.seperator} /> */}
        {/* <Link href='https://about.gamerarena.com/' passHref scroll={false}>
          <a
            className={classNames(styles.link, styles.aboutUs)}
            target='_blank'
          >
            <FormattedMessage defaultMessage='About Us' />
            <Arrow />
          </a>
        </Link> */}
      </div>
      {device === 'mobile' && (
        <div className={styles.supportCenter}>
          <p className={styles.title}>
            <FormattedMessage defaultMessage='SUPPORT CENTER' />
          </p>
          <div className={styles.tabs}>
            <Help />
            <Link href={router.pathname} passHref scroll={false}>
              <a className={styles.tab}>
                <FormattedMessage defaultMessage='Help Center' />
              </a>
            </Link>
          </div>
          <div className={styles.tabs}>
            <Faq />
            <Link
              href={`${router.pathname}/?action=FAQ`}
              passHref
              scroll={false}
            >
              <a className={styles.tab}>
                <FormattedMessage defaultMessage='FAQ' />
              </a>
            </Link>
          </div>
          <div className={styles.tabs}>
            <Teams />
            <Link href={router.pathname} passHref scroll={false}>
              <a className={styles.tab}>
                <FormattedMessage defaultMessage='Teams' />
              </a>
            </Link>
          </div>
          <div className={styles.languageSelector}>
            <LanguageSelector />
          </div>
        </div>
      )}
    </div>
  );

  useClickAway(section, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  useKeyDown(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, ['escape']);
  return (
    <div
      {...props}
      className={classNames(
        className,
        styles.landingMenu,
        device === 'mobile' && styles.mobile,
      )}
    >
      {device === 'mobile' ? (
        <>
          <BackgroundShade isVisible={isOpen} />

          <HeaderButton
            className={styles.navItem}
            onClick={() => setIsOpen(!isOpen)}
            type='button'
          >
            <Menu />
          </HeaderButton>
          <TransitionGroup component={null}>
            {isOpen && (
              <CSSTransition appear timeout={300}>
                <section
                  className={classNames(
                    styles.landingSideNav,
                    isOpen && styles.open,
                  )}
                  data-cy='onlineUsers'
                  ref={section}
                >
                  <div className={styles.closeContainer}>
                    <button
                      className={styles.closeButton}
                      onClick={() => setIsOpen(false)}
                      type='button'
                    >
                      <Close />
                    </button>
                  </div>
                  {content}
                </section>
              </CSSTransition>
            )}
          </TransitionGroup>
        </>
      ) : (
        content
      )}
    </div>
  );
}
