import React, { ChangeEvent, FC, useState } from 'react';
import classNames from 'classnames';
import styles from './Input.module.css';
import BoxLabel from '../../BoxLabel';
import hidePwdIcon from './hide-password.svg';
import showPwdIcon from './show-password.svg';

type InputProps = {
  name: string;
  label?: React.ReactNode;
  size?: FormComponentSize;
  inputClass?: string;
  type?: string;
  defaultValue?: string;
  placeholder?: string;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: any) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  unregister?: any; // TODO: type
  errorobj: any;
  autoComplete?: string;
  control?: any;
  value?: string | number;
  isVisible?: boolean;
  ref?: any;
  maxlength?: number;
};

const sizeStyles: Record<FormComponentSize, string> = {
  xlarge: styles.sizeXlarge,
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

export const Input: FC<InputProps> = ({
  size = 'medium',
  errorobj,
  type = 'text',
  label,
  className,
  name,
  placeholder,
  value,
  autoComplete,
  inputClass,
  maxlength,
  onChange,
  onBlur,
  onFocus,
  ref,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <div
      className={classNames(
        styles.inputWrapper,
        sizeStyles[size],
        errorobj && styles.hasError,
        type === 'password' && styles.iconInput,
        label === undefined && styles.noLabel,
        className,
      )}
    >
      {label && (
        <BoxLabel
          htmlFor={name}
          onTop={placeholder !== undefined || isFocused || !!value}
          size={size}
        >
          {label}
        </BoxLabel>
      )}

      <input
        {...otherProps}
        autoComplete={
          autoComplete && type === 'password' ? 'new-password' : autoComplete
        }
        className={classNames(
          styles.input,
          label && styles.withLabel,
          inputClass && inputClass,
        )}
        maxLength={maxlength}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur !== undefined && onBlur(event);
        }}
        onChange={onChange}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus !== undefined && onFocus(event);
        }}
        placeholder={placeholder}
        ref={ref}
        type={isRevealPwd ? 'text' : type}
        value={value}
      />

      {type === 'password' && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <img
          alt={isRevealPwd ? 'Hide password' : 'Show password'}
          className={styles.pwdIcon}
          onClick={() => setIsRevealPwd((prevState) => !prevState)}
          src={isRevealPwd ? hidePwdIcon : showPwdIcon}
        />
      )}
    </div>
  );
};
