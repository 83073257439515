import { init as initApm } from '@elastic/apm-rum';
import 'cropperjs/dist/cropper.css'; // eslint-disable-line import/no-extraneous-dependencies
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import 'dayjs/locale/tr';
import isBetween from 'dayjs/plugin/isBetween';
import dayjsLocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import firebase from 'firebase/app';
import 'focus-visible';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'tippy.js/dist/tippy.css'; // eslint-disable-line import/no-extraneous-dependencies
import { useRouter } from 'next/router';
import {
  AnalyticsProvider,
  AuthAndApiHandler,
  BreakpointHandler,
  IntercomHandler,
  DateTimeCheckModal,
  DuelCreationModal,
  FilterSortHandler,
  FirebaseAuthHandler,
  IntlProvider,
  MaintenanceModeHandler,
  NotificationsHandler,
  OnlineStatusHandler,
  OnlineUsersHandler,
  PhoneVerificationModal,
  PushNotificationHandler,
  RamadanCampaignHandler,
  RegionHandler,
  ShieldHandler,
  TagManagerHandler,
  OptimizeHandler,
  ToastsHandler,
  UserPage,
} from '../components';
import '../react-datepicker.css';
import { MetamaskStateProvider } from '../store/store';
import '../style.css';

dayjs.extend(isBetween);
dayjs.extend(dayjsLocalizedFormat);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

SwiperCore.use([Navigation]);

const firebaseConfig = {
  apiKey: 'AIzaSyBBUnA_iPyMZAgSGtr9oqrpESqh1lOdn8s',
  authDomain: 'login.gamerarena.com',
  databaseURL: 'https://gamerarena-b0218.firebaseio.com',
  projectId: 'gamerarena-b0218',
  storageBucket: 'gamerarena-b0218.appspot.com',
  messagingSenderId: '140126459077',
  appId: '1:140126459077:web:5faa855d2b5704820e0cac',
  measurementId: 'G-ZXQ0HMBNNL',
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
const firebaseConfig2 = {
  apiKey: 'AIzaSyCnOXz6xPRNUZrSK-_YGvx5EmAytVDq4b8',
  authDomain: 'gamerarenamobile.firebaseapp.com',
  projectId: 'gamerarenamobile',
  storageBucket: 'gamerarenamobile.appspot.com',
  messagingSenderId: '1001244836424',
  appId: '1:1001244836424:web:35747ffdec6484e981ce7d',
  measurementId: 'G-JP1FDFXLHE',
};
if (firebase.apps.length === 1) {
  firebase.initializeApp(firebaseConfig2, 'gaapp');
}

if (process.env.NEXT_PUBLIC_GAMERARENA_APM_ENABLED === 'true') {
  initApm({
    environment: process.env.NEXT_PUBLIC_GAMERARENA_STAGE,
    serviceName: 'gamerarena-frontend',
    serverUrl: process.env.NEXT_PUBLIC_GAMERARENA_APM_SERVER,
  });
}

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  let languageCodeFromLocalStorage: any = '';
  if (typeof window !== 'undefined') {
    const localStorageLanguageCodeKey = process.env
      .NEXT_PUBLIC_GAMERARENA_LOCAL_STORAGE_LANGUAGE_CODE_KEY as string;
    languageCodeFromLocalStorage = window.localStorage.getItem(
      localStorageLanguageCodeKey,
    );
  }

  return (
    <IntlProvider>
      <Head>
        <title>
          {languageCodeFromLocalStorage === 'en'
            ? 'Competitive Gaming Platform | Play to Earn | GA Coin'
            : 'Rekabetçi Oyun Platformu | Oyun Oyna Token Kazan | GA Coin'}
        </title>
        <script
          async
          crossOrigin='anonymous'
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5995039789570113'
        />
        <meta
          content={
            languageCodeFromLocalStorage === 'en'
              ? 'Gamer Arena is a competitive gaming platform where you can earn GA Coins by playing popular games like Clash Royale, Pubg, Fifa.'
              : 'Gamer Arena, Clash Royale, Pubg, Fifa gibi popüler oyunlarda mücadele ederek GA Coin kazanabileceğiniz rekabetçi oyun platformudur.'
          }
          name='description'
        />

        <link
          href='/apple-touch-icon.png'
          rel='apple-touch-icon'
          sizes='180x180'
        />
        <link
          href='/favicon-32x32.png'
          rel='icon'
          sizes='32x32'
          type='image/png'
        />
        <link
          href='/favicon-16x16.png'
          rel='icon'
          sizes='16x16'
          type='image/png'
        />
        <link href='/site.webmanifest' rel='manifest' />
        <meta
          content='width=device-width, user-scalable=no, initial-scale=1.0'
          name='viewport'
        />
        <meta content='#111217' name='msapplication-TileColor' />
        <meta content='#000000' name='theme-color' />
      </Head>

      <ShieldHandler>
        {router.pathname !== '/games' && router.pathname !== '/hitAndBoom' ? (
          <AuthAndApiHandler>
            <TagManagerHandler />
            <OptimizeHandler />

            <BreakpointHandler>
              <ToastsHandler>
                <NotificationsHandler>
                  <MaintenanceModeHandler>
                    <MetamaskStateProvider>
                      <RegionHandler>
                        <OnlineUsersHandler>
                          <RamadanCampaignHandler>
                            <OnlineStatusHandler />
                            <FirebaseAuthHandler />
                            <PushNotificationHandler />
                            <DateTimeCheckModal />
                            <IntercomHandler />

                            <FilterSortHandler>
                              <AnalyticsProvider category='Duel Creation Modal'>
                                <DuelCreationModal />
                              </AnalyticsProvider>
                              <PhoneVerificationModal />
                              <UserPage />

                              <Component {...pageProps} />
                            </FilterSortHandler>
                          </RamadanCampaignHandler>
                        </OnlineUsersHandler>
                      </RegionHandler>
                    </MetamaskStateProvider>
                  </MaintenanceModeHandler>
                </NotificationsHandler>
              </ToastsHandler>
            </BreakpointHandler>
          </AuthAndApiHandler>
        ) : (
          <>
            <Component {...pageProps} />
          </>
        )}
      </ShieldHandler>
    </IntlProvider>
  );
}
