import classNames from 'classnames';
import styles from './FilterButton.module.css';
import { ReactComponent as Filter } from './filter.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    filterCount: number | null;
    className: string;
  }
>;

export default function FilterButton({
  filterCount,
  className,
  ...props
}: Props) {
  return (
    <div {...props} className={classNames(styles.filterButton, className)}>
      <div className={styles.icon}>
        <Filter />

        {filterCount !== null && (
          <div className={styles.filledIndicator}>{filterCount}</div>
        )}
      </div>
    </div>
  );
}
