import { createContext } from 'react';

type Value = {
  api: {
    destroy(
      endpoint: string,
      payload?: object,
      config?: Omit<RequestInit, 'body' | 'method'>,
    ): Promise<Response>;
    get: (endpoint: string, init?: RequestInit) => Promise<Response>;
    patch(
      endpoint: string,
      payload: object,
      config?: Omit<RequestInit, 'body' | 'method'>,
    ): Promise<Response>;
    post(
      endpoint: string,
      payload: object,
      config?: Omit<RequestInit, 'body' | 'method'>,
    ): Promise<Response>;
  };
  inProgress: boolean;
  setUserAccessToken: (userAccessToken: UserAccessToken | null) => void;
  setUserBasicToken: (userBasicToken: UserBasicToken | null) => void;
  setDeviceToken: (deviceToken: DeviceToken | null) => void;
  user: AuthUser | null | any;
  userAccessToken: UserAccessToken | null;
  userBasicToken: UserBasicToken | null;
  deviceToken: DeviceToken | null;
};

const AuthAndApiContext = createContext<Value>({} as Value);

export default AuthAndApiContext;
