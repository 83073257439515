import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizationContext } from '../../contexts';
import Container from '../Container';
import { ReactComponent as Arrow } from './arrow.svg';
import furkanImageSource from './furkanarabaci.png';
import seeOkImageSource from './seeOk.png';
import { ReactComponent as Quotes } from './quotes.svg';
import styles from './Testimonials.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  className?: string;
};

type Item = {
  content: React.ReactNode;
  details: React.ReactNode;
  header: React.ReactNode;
  name: string;
  imageSource: string;
};

export default function Testimonials({ className, ...props }: Props) {
  const { dir } = useContext(LocalizationContext);
  const [isSliding, setIsSliding] = useState<boolean>(false);
  const intl = useIntl();

  const testimonials = useMemo<Item[]>(
    () => [
      {
        content: (
          <p>
            {intl.formatMessage({
              defaultMessage:
                '"Clash Royale, the game that I am an expert on, is a game that requires seriousness and focus. But most of the time, we lose focus because we do not take the game seriously. But if there is a reward system at the end of a competition, your fun increases in proportion to the focus you live. Gamer Arena is a nice platform that comes into play at this point and you can win prizes."',
            })}
          </p>
        ),
        details: intl.formatMessage({
          defaultMessage: 'Gamer Arena Clash Royale Esports Athlete - Youtuber',
        }),
        header: 'SeeOk GA',
        name: 'Semih "SeeOk GA" Kaya',
        imageSource: seeOkImageSource.src,
      },
      {
        content: (
          <>
            <p>
              {intl.formatMessage({
                defaultMessage:
                  '"I have been playing Clash Royale professionally for a long time. The best way to improve in Clash Royale is to practice with competitive matches in tournaments. At this point, you can improve yourself by practicing with competitive matches thanks to Gamer Arena. Having prizes also adds fun."',
              })}
            </p>
          </>
        ),
        details: intl.formatMessage({
          defaultMessage: 'Gamer Arena Clash Royale Esports Athlete - Youtuber',
        }),
        header: 'FURKANARABACI GA',
        name: 'Furkan "FURKANARABACI GA" Arabacı',
        imageSource: furkanImageSource.src,
      },
    ],
    [],
  );

  return (
    <div {...props} className={classNames(className, styles.testimonials)}>
      <Container>
        <div className={styles.title}>
          <p className={styles.main}>
            <FormattedMessage defaultMessage='Gamers Saying About Us...' />
          </p>

          <p className={styles.subTitle}>
            <FormattedMessage defaultMessage='Hear about what it is like to compete in the arena from our users.' />
          </p>
        </div>
        <div>
          <Swiper
            className={styles.slider}
            dir={dir}
            id='testimonials'
            initialSlide={0}
            navigation={{
              nextEl: `.${styles.next}`,
              prevEl: `.${styles.prev}`,
              disabledClass: `${styles.disabled}`,
            }}
            onSlideChangeTransitionEnd={() => setIsSliding(false)}
            onSlideChangeTransitionStart={() => setIsSliding(true)}
            slidesPerView={1}
            tag='section'
          >
            {testimonials.map(
              ({ content, details, header, name, imageSource }) => (
                <SwiperSlide className={styles.slide} key={name}>
                  <div className={styles.col}>
                    <img alt='person' src={imageSource} />
                  </div>

                  <div className={classNames(styles.col, styles.alignEnd)}>
                    <h2 className={styles.header}>
                      {header}

                      <Quotes className={styles.quotes} />
                    </h2>
                    <div className={styles.content}>{content}</div>
                    <div className={styles.sub}>
                      <p className={styles.name}>{name}</p>
                      <p className={styles.details}>{details}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ),
            )}

            <div
              className={classNames(styles.arrows, isSliding && styles.hide)}
              slot='container-end'
            >
              <button
                aria-label='previous'
                className={classNames(styles.navigation, styles.prev)}
                type='button'
              >
                <Arrow />
              </button>
              <button
                aria-label='next'
                className={classNames(styles.navigation, styles.next)}
                type='button'
              >
                <Arrow />
              </button>
            </div>
          </Swiper>
        </div>
      </Container>
    </div>
  );
}
