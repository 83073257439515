/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { io } from 'socket.io-client';
import axios from 'axios';
import {
  AnalyticsContext,
  AuthAndApiContext,
  BreakpointContext,
} from '../../contexts';
import { createGtag } from '../../helpers';
/* import Metamask from '../../pages/metamask'; */
import Button from '../Button';
import Container from '../Container';
import LandingMenu from '../Landing/LandingMenu';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import NotificationMenu from '../NotificationMenu';
import OnlineUsers from '../OnlineUsers';
import UserMenu from '../UserMenu';
import WalletMenu from '../WalletMenu';
import styles from './Header.module.css';
import TakeRewardComp from '../TakeRewardComp';
import { ReactComponent as Ticket } from './ticket.svg';

// import resim from './logos.png';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['header']> & {
  className?: string;
};

type TournamentRule = {
  number: number | null;
};

export default function Header({ className, ...props }: Props) {
  const router = useRouter();
  const { device } = useContext(BreakpointContext);
  const { category } = useContext(AnalyticsContext);
  const { user } = useContext(AuthAndApiContext);
  const inLanding =
    router.pathname === '/landing/v1' || router.pathname === '/landing/v2';

  const redirectToHomepage = () => {
    // Anasayfaya yönlendirme işlemini burada gerçekleştirin
    router.replace('https://gamerarena.com/arena');
  };
  const redirectToTargetPage = () => {
    const propsToPass = {
      greeting: '1',
    };

    router.replace({
      pathname: 'https://about.gamerarena.com/',
      query: propsToPass,
    });
    // Anasayfaya yönlendirme işlemini burada gerçekleştirin
    // window.location.href = 'http://localhost:4001/games';
  };

  function generateRandomCode(length: number) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = length || 8;
    let code = '';

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  }

  const sessionId = sessionStorage.getItem('sessionID');

  useEffect(() => {
    // WebSocket bağlantısını kur
    const socket = io(
      'https://sociallogingamerarena-e1e00ae7384c.herokuapp.com',
      {
        withCredentials: true,
      },
    );

    console.log('session', sessionId);

    if (!sessionId) {
      const abc = generateRandomCode(6);
      sessionStorage.setItem('sessionID', abc); // setItem kullanılacak
      socket.emit('authenticate', { id: abc }); // Kullanıcının kimlik bilgisini nesne olarak gönderin
    }

    socket.on('authenticated', (user1) => {
      console.log('object', user1);
    });

    socket.on('redirectToHomepage', () => {
      redirectToHomepage();
    });
    socket.on('redirectToTargetPage', () => {
      redirectToTargetPage();
    });

    return undefined;
  }, []);

  const [tournamentTicket, setTournamentTicket] = useState<TournamentRule>({
    number: null,
  });

  const localStorageBasicTokenKey = process.env
    .NEXT_PUBLIC_GAMERARENA_LOCAL_STORAGE_BASIC_TOKEN_KEY as string;
  const token = window.localStorage.getItem(
    localStorageBasicTokenKey,
  ) as string;

  async function getDepositRules() {
    const getDepositRule = await axios.get(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/users/tournamentTicket`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    if (getDepositRule.status === 200) {
      setTournamentTicket({ number: getDepositRule.data.number });
    }
  }

  useEffect(() => {
    getDepositRules();
  }, []);

  return (
    <>
      <header {...props} className={classNames(styles.header, className)}>
        {/* <p>Anlık ziyaretçi sayısı: {visitorCount}</p> */}
        {/* <div
          onClick={() => {
            window.open(
              'https://gleam.io/ikZOb/gamer-arena-social-media-campaign',
              '_blank',
            );
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              window.open(
                'https://gleam.io/ikZOb/gamer-arena-social-media-campaign',
                '_blank',
              );
            }
          }}
          role='button'
          style={{
            height: device !== 'mobile' ? '30px' : '70px',
            backgroundColor: '#2d2d2d',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            cursor: 'pointer',
          }}
          tabIndex={0}
        >
          <FormattedMessage defaultMessage=' 🎉 Follow us on social media 🎉🎉🎉 and win GA coins! 💰 🤑 💸 💵 ➡️' />
        </div> */}

        <Container
          className={classNames(
            styles.container,
            (router.pathname === '/games' ||
              router.pathname === '/hitAndBoom') &&
              styles.games,
            user === null && styles.notAuthenticated,
            inLanding && styles.forLanding,
          )}
          fluid
        >
          {router.pathname === '/games' || router.pathname === '/hitAndBoom' ? (
            <div className={styles.gamesContent}>
              <div className={styles.logo}>
                <Link href={user === null ? '/' : '/arena'}>
                  <a className={styles.logoLink}>
                    <Logo className={styles.logo} />
                    {/* <img alt='Asset 1' className={styles.logo} src={resim.src} /> */}
                  </a>
                </Link>
              </div>

              <div className={styles.buttonsContainer}>
                {router.pathname === '/games' && <TakeRewardComp />}

                <Link href='/arena' passHref>
                  <Button variant='secondary'>
                    <FormattedMessage defaultMessage='Go to Arena' />
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              {user === null ? (
                <>
                  {!inLanding && (
                    <Link href='/app' passHref>
                      <Button size='small' variant='primary'>
                        <FormattedMessage defaultMessage='Gamer Arena App' />
                      </Button>
                    </Link>
                  )}

                  {/* {device !== 'mobile' && !inLanding && (
                  <Link href='/hitAndBoom' passHref>
                    <a>
                      <Button className={styles.hitAndBoom} size='small'>
                        <FormattedMessage defaultMessage='Hit And Boom' />
                      </Button>
                    </a>
                  </Link>
                )} */}

                  {device !== 'mobile' && (
                    <div className={styles.logoWrapper}>
                      <Link href={router.pathname}>
                        <a
                          aria-label='Gamer Arena'
                          className={classNames(
                            device === 'desktop' && styles.logoWithSpace,
                          )}
                        >
                          <Logo className={styles.logo} />
                          {/* <img
                          alt='Asset 1'
                          className={styles.logo}
                          src={resim.src}
                          style={{ width: '300px', height: '100%' }}
                        /> */}
                        </a>
                      </Link>
                    </div>
                  )}

                  {device !== 'mobile' && inLanding && (
                    <LandingMenu device='desktop' />
                  )}
                  <div className={styles.buttonsContainer}>
                    <div className={styles.languageSelector}>
                      <LanguageSelector />
                    </div>

                    {device !== 'mobile' && (
                      <Link
                        href={
                          inLanding
                            ? `${router.pathname}/?action=sign-in`
                            : '/?action=sign-in'
                        }
                        passHref
                        scroll={false}
                      >
                        <Button size='small' variant='primary'>
                          <FormattedMessage defaultMessage='Sign In' />
                        </Button>
                      </Link>
                    )}
                  </div>

                  {device === 'mobile' && inLanding && (
                    <LandingMenu device='mobile' />
                  )}
                </>
              ) : (
                <>
                  {device !== 'mobile' && (
                    <div className={styles.logoWrapper}>
                      <Link href={router.pathname}>
                        <a
                          aria-label='Gamer Arena'
                          className={classNames(styles.logoWithSpace)}
                        >
                          <Logo className={styles.logo} />
                          {/* <img
                          alt='Asset 1'
                          className={styles.logo}
                          src={resim.src}
                        /> */}
                        </a>
                      </Link>
                    </div>
                  )}

                  {device !== 'mobile' && inLanding && (
                    <LandingMenu device='desktop' />
                  )}

                  {device !== 'mobile' && !inLanding && (
                    <Link
                      href={{
                        pathname: router.pathname,
                        query: {
                          ...router.query,
                          duelCreation: 'true',
                        },
                      }}
                      passHref
                    >
                      <Button
                        data-gtag={createGtag({
                          category,
                          event: 'Open Custom Duel Modal',
                          label: 'Header',
                        })}
                        size='small'
                        variant='secondary'
                      >
                        <FormattedMessage defaultMessage='Create Duel' />
                      </Button>
                    </Link>
                  )}

                  {device !== 'mobile' && !inLanding && (
                    <Link
                      href='https://about.gamerarena.com/#ourProductWeb3'
                      passHref
                    >
                      <a>
                        <Button size='small' style={{ background: '#9932CC' }}>
                          <FormattedMessage defaultMessage='GAU Arena Web3.0 App' />
                        </Button>
                      </a>
                    </Link>
                  )}

                  {device !== 'mobile' && !inLanding && (
                    <Link href='/app' passHref>
                      <Button size='small' variant='primary'>
                        <FormattedMessage defaultMessage='Gamer Arena App' />
                      </Button>
                    </Link>
                  )}

                  {/* {device !== 'mobile' && !inLanding && (
                  <Link href='/hitAndBoom' passHref>
                    <a>
                      <Button className={styles.hitAndBoom} size='small'>
                        <FormattedMessage defaultMessage='Hit And Boom' />
                      </Button>
                    </a>
                  </Link>
                )} */}

                  {device === 'mobile' && <UserMenu />}

                  {device === 'mobile' && <WalletMenu />}

                  {device === 'mobile' && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Ticket
                        style={{
                          height: '24px',
                          width: 'auto',
                        }}
                      />
                      <span
                        style={{
                          marginLeft: '8px',
                          fontSize: '20px',
                        }}
                      >
                        {tournamentTicket?.number || 0}
                      </span>
                    </div>
                  )}

                  {/* {device === 'mobile' && !inLanding && (
                  <Metamask device='mobile' />
                )} */}

                  <div className={styles.separator} />

                  {/* {device !== 'mobile' && !inLanding && (
                  <Metamask device='desktop' />
                )} */}

                  {device !== 'mobile' && (
                    <div
                      onClick={() => {
                        window.location.href =
                          'https://gamerarena.com/wallet/deposit';
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <Ticket
                        style={{
                          height: '32px',
                          width: 'auto',
                          marginTop: '15%',
                        }}
                      />
                      <span
                        style={{
                          marginLeft: '8px',
                          marginTop: '12%',
                          fontSize: '20px',
                        }}
                      >
                        {tournamentTicket?.number || 0}
                      </span>
                    </div>
                  )}
                  {device !== 'mobile' && <WalletMenu />}

                  <NotificationMenu />

                  <OnlineUsers />

                  {device !== 'mobile' && <UserMenu />}

                  {device === 'mobile' && inLanding && (
                    <LandingMenu device='mobile' />
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </header>
    </>
  );
}
