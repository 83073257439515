const MobileLegendsDiamonds: number[] = [
  2500, 1500, 1000, 500, 500, 500, 500, 500, 500, 500, 250, 250, 250, 250, 250,
  250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 150, 150, 150, 150,
  150, 150, 150, 150, 150, 150, 150, 150, 150, 50, 50, 50, 50, 50, 50, 50, 50,
  50, 50, 50,
];
const MobileLegendsIDS = [684, 714, 723];
const TREASURE_MLID = 701;
const MOBILE_LEGENDS_HOME_ID = 109;
const MLBB_CONFIG = [
  {
    id: 684,
    diamonds: [
      2500, 1500, 1000, 500, 500, 500, 500, 500, 500, 500, 250, 250, 250, 250,
      250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 250, 150, 150, 150,
      150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 50, 50, 50, 50, 50, 50,
      50, 50, 50, 50, 50,
    ],
  },
  {
    id: 714,
    diamonds: [
      2000, 1000, 700, 500, 500, 500, 500, 500, 300, 250, 150, 150, 150, 150,
      150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 75, 75, 75, 75, 75,
      75, 75, 75, 75, 75, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
      50, 50,
    ],
  },
  {
    id: 723,
    diamonds: [
      2000, 1000, 700, 500, 500, 500, 500, 500, 300, 250, 150, 150, 150, 150,
      150, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 75, 75, 75, 75, 75,
      75, 75, 75, 75, 75, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50,
      50, 50,
    ],
  },
];
const PMGC_TICKET_TOURNAMENTID = 756;

export {
  MobileLegendsDiamonds,
  MOBILE_LEGENDS_HOME_ID,
  TREASURE_MLID,
  MobileLegendsIDS,
  MLBB_CONFIG,
  PMGC_TICKET_TOURNAMENTID,
};
