import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import styles from './LandingCounter.module.css';
import { useInViewPort, useInterval } from '../../../hooks';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['p']>,
  {
    value: number;
    letter: string;
  }
>;

export default function LandingCounter({
  className,
  value,
  letter,
  ...props
}: Props) {
  const [counter, setCounter] = useState(0);
  const [isContinue, setIsContinue] = useState(true);

  const counterRef = useRef(null);

  const isInViewport1 = useInViewPort(counterRef);

  let timer = 7;

  if (value === 7.85) {
    timer = 150;
  } else if (value === 404) {
    timer = 3;
  }

  useInterval(
    () => {
      isContinue && setCounter(counter + 1);
      if (Math.floor(value) === counter) {
        setCounter(value);
        setIsContinue(false);
      }
    },
    timer,
    isInViewport1,
  );

  return (
    <p
      {...props}
      className={classNames(styles.counter, className)}
      id='counter'
      ref={counterRef}
    >
      {`${counter}`}
      {letter}
    </p>
  );
}
