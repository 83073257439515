import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import GACoin from '../GACoin';
import { ReactComponent as First } from './first.svg';
import { ReactComponent as Fourth } from './fourth.svg';
import styles from './LeaderboardTournamentPrizes.module.css';
import { ReactComponent as Second } from './second.svg';
import { ReactComponent as Third } from './third.svg';
import Poco from './poko.png';
import Diamond from './diamond.png';
import PMGC from './PMGC.png';
import { MLBB_CONFIG, PMGC_TICKET_TOURNAMENTID } from '../../helpers';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    leaderboard: Leaderboard;
  }
>;

export default function LeaderboardTournamentPrizes({
  className,
  leaderboard,
  ...props
}: Props) {
  const ranks = useMemo<React.ReactNode[]>(
    () => [
      <First key='leaderboard-first' />,
      <Second key='leaderboard-second' />,
      <Third key='leaderboard-third' />,
      <Fourth key='leaderboard-fourth' />,
    ],
    [leaderboard.rewards],
  );
  const isMLBB = MLBB_CONFIG.find((itm) => itm.id === leaderboard.id);
  const isPMGCTournament = leaderboard.id === PMGC_TICKET_TOURNAMENTID;
  return leaderboard.rewards.length === 0 ? null : (
    <section
      {...props}
      className={classNames(styles.leaderboardTournamentPrizes, className)}
    >
      <div className={styles.header}>
        <h4>
          <FormattedMessage defaultMessage='Prizes Table' />
        </h4>
      </div>

      <div className={styles.body}>
        {leaderboard.rewards
          .sort((a, b) => b - a)
          .map((reward, index) => (
            <div className={styles.info} key={`${reward}-${index + 1}`}>
              <div className={styles.detail}>
                {ranks[index]}

                <p>{`#${index + 1}`}</p>
              </div>

              <div className={classNames(styles.detail, styles.reward)}>
                {leaderboard.id === 653 && index === 0 ? (
                  <img alt='POCO' className={styles.gaCoin} src={Poco.src} />
                ) : (
                  <GACoin className={styles.gaCoin} />
                )}

                <p>
                  {leaderboard.id === 653 && index === 0 ? (
                    'POCO F5 PRO'
                  ) : (
                    <>
                      {reward}
                      {(!isPMGCTournament ||
                        (isPMGCTournament && index > 14)) &&
                        ` GA Coin `}
                    </>
                  )}
                </p>

                {isMLBB?.diamonds?.[index] && (
                  <>
                    <img
                      alt='Diamond'
                      className={styles.diamond}
                      src={Diamond.src}
                    />
                    <p>
                      {isMLBB?.diamonds?.[index]}
                      {' Elmas'}
                    </p>
                  </>
                )}
                {isPMGCTournament && index <= 14 && (
                  <>
                    <img alt='Diamond' className={styles.pmgc} src={PMGC.src} />
                    <p>PMGC Bileti</p>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}
