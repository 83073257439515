/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import RS, { components } from 'react-select';
import BoxLabel from '../BoxLabel';
import styles from './Select.module.css';
import { ReactComponent as ArrowDown } from './arrow-down.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['select']>,
  {
    disabled?: boolean;
    id: string;
    onChange: (e: any) => void;
    name?: string;
    label?: React.ReactNode;
    options?: any;
    position?: 'fixed' | 'absolute';
    menuPlacement?: 'auto' | 'top' | 'bottom';
    size?: FormComponentSize;
    required?: boolean;
  }
>;

type OptionType = {
  value: string;
  label: string;
  disabled?: boolean;
};

const sizeStyles: Record<FormComponentSize, string> = {
  xlarge: styles.sizeXlarge,
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator
    {...props}
    className='react-select__indicatorContainer'
  >
    <ArrowDown className='react-select__arrowDown' />
  </components.DropdownIndicator>
);

function Select({
  disabled,
  id,
  name,
  label,
  options,
  onChange,
  position = 'absolute',
  required = false,
  menuPlacement = 'auto',
  size = 'medium',
  value,
}: Props) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const selectRef = useRef<any>(null);

  const valueMemo = useMemo(
    () =>
      options
        ? options.filter(
            (opt: { value: string | number | readonly string[] | undefined }) =>
              opt.value === value,
          )
        : [],
    [value],
  );

  const opts: OptionType[] = options;

  return (
    <div
      className={classNames(
        styles.select,
        sizeStyles[size],
        isFocused && styles.focused,
        (disabled || (opts && opts.length === 1)) && styles.disabled,
      )}
    >
      {label && (
        <BoxLabel
          htmlFor={id}
          onTop={!!value || value === 0 || isSelected}
          size={size}
        >
          {label}
        </BoxLabel>
      )}
      <RS
        className='react-select-container'
        classNamePrefix='react-select'
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        defaultValue={valueMemo}
        inputId={id}
        isDisabled={disabled || opts?.length === 1}
        isOptionDisabled={(option) => option.disabled}
        isSearchable={false}
        menuPlacement={menuPlacement}
        // menuPortalTarget={document.body}
        menuPosition={position}
        name={name}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => {
          onChange(event);
          setIsSelected(!!value);
        }}
        onFocus={() => setIsFocused(true)}
        options={opts}
        placeholder={null}
        ref={selectRef}
        required
        /* styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }} */
        value={valueMemo}
      />
      {/* Required için eklendi */}
      <input
        autoComplete='off'
        onFocus={() => selectRef.current?.focus()}
        required={required}
        style={{
          opacity: 0,
          width: '100%',
          position: 'absolute',
        }}
        tabIndex={-1}
        value={valueMemo}
      />
    </div>
  );
}

export default React.forwardRef(Select);
