import classNames from 'classnames';
import React from 'react';
import InlineLabel from '../InlineLabel';
import styles from './Checkbox.module.css';

type Size = 'large' | 'medium' | 'small';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['input']>,
  {
    id: string;
    imageRadius?: boolean;
    label?: React.ReactNode;
    labelImage?: string;
    size?: Size;
    labelClassName?: string;
  }
>;

export default function Checkbox({
  checked,
  className,
  id,
  imageRadius = true,
  labelImage,
  labelClassName,
  label,
  size = 'medium',
  ...props
}: Props) {
  const sizeStyle: Record<Size, string> = {
    large: styles.sizeLarge,
    medium: styles.sizeMedium,
    small: styles.sizeSmall,
  };

  return (
    <label
      className={classNames(
        styles.checkbox,
        checked && styles.isActive,
        checked && 'accessChecked',
        className,
        sizeStyle[size],
      )}
      htmlFor={id}
    >
      <input
        {...props}
        checked={checked}
        className={styles.input}
        id={id}
        type='checkbox'
      />

      {label !== undefined && (
        <InlineLabel
          className={classNames(styles.inlineLabel, labelClassName)}
          image={labelImage}
          imageRadius={imageRadius}
          size={size}
        >
          {label}
        </InlineLabel>
      )}
    </label>
  );
}
