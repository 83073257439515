import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';

const TakeRewardComp = () => {
  const [count, setCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(600); // 10 dakika = 600 saniye
  const [showRewardButton, setShowRewardButton] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);

  useEffect(() => {
    window.focus();

    const handleMouseMove = () => {
      setTimeout(() => {
        setIsDeactivated(true);
      }, 5 * 60 * 1000);
      setCount((prev) => prev + 1);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (isDeactivated) setTimeRemaining(600);
  }, [isDeactivated]);

  useEffect(() => {
    if (!showRewardButton) {
      if (count > 0) {
        // Her saniyede bir geri sayımı güncelle
        const timer = setInterval(() => {
          setTimeRemaining((prevTime) => prevTime - 1);
        }, 1000);

        // 10 dakika bittiğinde ödül butonunu göster
        if (timeRemaining <= 0) {
          setShowRewardButton(true);
          clearInterval(timer); // Geri sayımı durdur
        }

        // Component kaldırıldığında interval'i temizle
        return () => clearInterval(timer);
      }
    }
    return undefined;
  }, [timeRemaining, count]);

  const localStorageBasicTokenKey = process.env
    .NEXT_PUBLIC_GAMERARENA_LOCAL_STORAGE_BASIC_TOKEN_KEY as string;
  const token = window.localStorage.getItem(
    localStorageBasicTokenKey,
  ) as string;

  let isRequestInProgress = false; // Kilit sistemi için bir bayrak oluşturun

  const handleRewardButtonClick = async () => {
    // Eğer bir istek halihazırda devam ediyorsa fonksiyonu sonlandır
    if (isRequestInProgress) {
      return;
    }

    // Bayrağı true yaparak bir istek yapıldığını belirtin
    isRequestInProgress = true;

    try {
      const requestPayment = await axios.post(
        `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/withdraw/yandexRewards`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        },
      );

      if (requestPayment.status === 200) {
        window.open('https://gamerarena.com/arena', '_blank');
        setTimeRemaining(600);
        setShowRewardButton(false);
      }
    } catch (error) {
      // İstek sırasında bir hata meydana gelirse bayrağı false yaparak yeniden istek yapılmasına izin verin
      console.error('Error handling reward button click:', error);
    } finally {
      // İsteği tamamladıktan sonra bayrağı false yaparak yeniden istek yapılmasına izin verin
      isRequestInProgress = false;
    }
  };

  return (
    <div>
      {timeRemaining > 0 && count > 0 && (
        <a>
          Ödüle kalan süre: {Math.floor(timeRemaining / 60)}:
          {timeRemaining % 60 < 10 ? '0' : ''}
          {timeRemaining % 60}
        </a>
      )}
      {showRewardButton && (
        <div>
          <Button
            onClick={handleRewardButtonClick}
            size='small'
            variant='primary'
          >
            <FormattedMessage defaultMessage='Ödülü Al' />
          </Button>
        </div>
      )}
    </div>
  );
};

export default TakeRewardComp;
