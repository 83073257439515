// @ts-nocheck

// https://github.com/athomasjr/react-hook-compound-components/tree/main/src
// Hook smart form yapısında metodları bir üst bileşene çıkarıldı fakat method içindeki değerler üst bileşene güncel aktarılmadığı için hook
// formun standart yapısında kullanıldı. Buradaki yapı ilerisi için denenebilir.
import React, {
  Children,
  isValidElement,
  cloneElement,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup } from '../FormGroup';
import { FieldErrorMessage } from '../FormErrorMessage';

type FormProps = {
  defaultValues?: Record<string, any>;
  autoComplete?: string;
  children: React.ReactNode;
  onSubmit: (
    data: Record<string, any>,
    event?: React.BaseSyntheticEvent,
  ) => void;
  schema: any; // TODO: add yup schema type
  onLoad?: (methods: any) => void;
  watchFields?: (data: any) => void;
};

function recursiveMap(children, fn) {
  return Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      // eslint-disable-next-line no-param-reassign
      child = cloneElement(child, {
        children: recursiveMap(child.props.children, fn),
      });
    }

    return fn(child);
  });
}

export const Form = ({
  defaultValues,
  children,
  onSubmit,
  schema,
  autoComplete,
  onLoad,
  watchFields,
}: FormProps) => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    control,
    formState,
    register,
    unregister,
    watch,
    getValues,
  } = methods;

  useEffect(() => {
    const values = getValues();

    watchFields && watchFields({ values });
    onLoad && onLoad(methods);
  }, []);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      watchFields({
        values,
        name,
        type,
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    onLoad && onLoad(methods);
  }, [formState]);

  const arrayChildren = Children.toArray(children);

  return (
    <form
      autoComplete={autoComplete}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      {autoComplete && (
        <input
          autoComplete='off'
          className='hidden'
          name='hidden'
          type='text'
        />
      )}
      {arrayChildren &&
        recursiveMap(arrayChildren, (child: any) => {
          const { name } = child.props;
          const isVisible =
            'isVisible' in child.props ? child.props.isVisible : true;
          const error =
            isVisible && formState.errors[name]
              ? formState.errors[name]
              : undefined;

          return child?.props?.name
            ? React.createElement(
                FormGroup,
                { ...child.props },
                React.createElement(child.type, {
                  ...{
                    ...child.props,
                    control,
                    error,
                    register,
                    unregister,
                    autoComplete,
                    key: child.props.name,
                  },
                }),
                error &&
                  React.createElement(FieldErrorMessage, {
                    error: formState.errors[child.props.name]?.message, // TODO:
                  }),
              )
            : child;
        })}
    </form>
  );
};
