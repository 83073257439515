/* eslint-disable  @next/next/no-sync-scripts */

import React from 'react';
import Head from 'next/head';

export default function OptimizeHandler() {
  return (
    // eslint-disable-next-line @next/next/no-script-in-head
    <Head>
      <script src='https://www.googleoptimize.com/optimize.js?id=OPT-T2X88WX' />
    </Head>
  );
}
