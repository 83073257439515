export function createGtag({
  category,
  event,
  label,
  value,
}: AnalyticsEvent): string {
  const eventParams = {
    category,
    label,
    value,
  };

  return JSON.stringify({
    event,
    event_params: eventParams,
  });
}

export function sendEcommerceEvent({
  currencyCode,
  event,
  transactionId,
  transactionTotal,
}: AnalyticsEcommerceEvent) {
  window.dataLayer.push({
    event: event || 'purchase',
    ecommerce: {
      transaction_id: transactionId,
      value: transactionTotal,
      currency: currencyCode,
    },
  });
}

export function sendGAEvent({
  category,
  event,
  label,
  value,
}: AnalyticsEvent | any) {
  const eventParams = {
    category,
    label,
    value,
  };

  window.dataLayer.push({
    event,
    event_params: eventParams,
  });
}

export function listenGtmEvents(e: Event) {
  const trackedElement: HTMLAnchorElement | HTMLButtonElement | null = (
    e.target as HTMLElement
  ).closest('[data-gtag]');

  if (trackedElement !== null) {
    const { gtag } = trackedElement.dataset;

    if (gtag) {
      sendGAEvent({ ...JSON.parse(gtag) });
    }
  }
}
