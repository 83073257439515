import React, { createContext } from 'react';

export type StateKey = 'game' | 'gameMode' | 'platform' | 'entryFee';
export type StateValue = number | null;

export type State = Record<StateKey, StateValue>;

type FilterSort = {
  toggleFilter: boolean;
  setToggleFilter: React.Dispatch<React.SetStateAction<boolean>>;

  filterCount: number | null;
  setFilterCount: React.Dispatch<React.SetStateAction<number | null>>;

  duelStatus: Record<DuelStatus, string>;

  selectedDuelStatus: DuelFilterSortSelectedDuelStatus;
  setSelectedDuelStatus: React.Dispatch<
    React.SetStateAction<DuelFilterSortSelectedDuelStatus>
  >;

  selectedDuelFilters: State;
  setSelectedDuelFilters: React.Dispatch<
    React.SetStateAction<FilterSort['selectedDuelFilters']>
  >;

  selectedOrderingOption: DuelFilterOrdering;
  setSelectedOrderingOption: React.Dispatch<
    React.SetStateAction<FilterSort['selectedOrderingOption']>
  >;

  orderingOptions: DuelFilterOrderingOption[];
};

const FilterSortContext = createContext<FilterSort>({} as FilterSort);

export default FilterSortContext;
