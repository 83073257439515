import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn, useForm } from 'react-hook-form';
import { entryFeeChoices } from '../../constants';
import {
  AuthAndApiContext,
  FilterSortContext,
  RegionContext,
} from '../../contexts';
import { hydrateGameMode, sendGAEvent } from '../../helpers';
import Button from '../Button';
import Container from '../Container';
import GameModeTooltip from '../GameModeTooltip';
import Modal from '../Modal';
import QuickDuelTournamentInfo from '../QuickDuelTournamentInfo';
import RequiredAccountFieldDisplay from '../RequiredAccountFieldDisplay';
import styles from './QuickDuel2.module.css';
import { ReactComponent as Swords } from './swords.svg';
import {
  CommonError,
  FormGroup,
  FormInput,
  FormSelect,
  yupCustom as yup,
} from '../Form';
import ActivityIndicator from '../ActivityIndicator';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  className?: string;
};

type FormType = {
  entryFee: number;
  game: number | null;
  gameMode: number | null;
  platform: number | null;
  requiredAccountField?: string;
};

const defaultValues: FormType = {
  entryFee: 20,
  game: null,
  gameMode: null,
  platform: null,
  requiredAccountField: '',
};

export default function QuickDuel2({ className, ...props }: Props) {
  const router = useRouter();
  const intl = useIntl();
  const { api, user } = useContext(AuthAndApiContext);
  const { region } = useContext(RegionContext);
  const { selectedDuelFilters, setSelectedDuelFilters } =
    useContext(FilterSortContext);

  const [isModalActionDisabled, setIsModalActionDisabled] =
    useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<any>(null);

  let hydratedGameMode: any = null;
  const validationSchema = yup.object({
    entryFee: yup.number(),
    game: yup.number().nullable(true).required(),
    gameMode: yup.number().nullable(true).required(),
    platform: yup.number().nullable(true).required(),
    requiredAccountField: yup.lazy((value: any) => {
      if (
        value !== null &&
        user![hydratedGameMode?.requiredAccountField] === null
      ) {
        return yup.string().required();
      }

      return yup.string();
    }),
  });

  // @ts-ignore
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    watch,
    setError,
    unregister,
    control,
  }: UseFormReturn<FormType> = methods;

  const watchGame = watch('game');
  const watchPlatform = watch('platform');
  const watchGameMode = watch('gameMode');

  const fetcher = (url: any) => fetch(url).then((r) => r.json());

  const { data: games } = useSWR<Game[]>(
    `/games/?is_active=true&region=${region.id}`,
  );

  const { data: platforms } = useSWR<Platform[]>(
    watchGame === null
      ? null
      : // eslint-disable-next-line max-len
        `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/platforms/?is_active=true&game=${watchGame}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('platform') === null && data?.length === 1) {
          setValue('platform', data[0].id, {
            shouldValidate: true,
          });

          setSelectedDuelFilters({
            ...selectedDuelFilters,
            platform: data[0].id,
          });
        }
      },
    },
  );

  const { data: gameModes } = useSWR<GameMode[]>(
    watchGame === null || watchPlatform === null
      ? null
      : // eslint-disable-next-line max-len
        `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/game_modes/?is_active=true&game=${watchGame}&platform=${watchPlatform}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('gameMode') === null && data?.length === 1) {
          setValue('gameMode', data[0].id, {
            shouldValidate: true,
          });

          setSelectedDuelFilters({
            ...selectedDuelFilters,
            gameMode: data[0].id,
          });
        }
      },
    },
  );

  const { data: gameMode } = useSWR<GameMode>(
    watchGameMode === null ? null : `/games/game_modes/${watchGameMode}/`,
  );

  hydratedGameMode = useMemo<HydratedGameMode | null>(() => {
    if (gameMode === undefined) return null;

    return hydrateGameMode(gameMode);
  }, [gameMode]);

  useEffect(() => {
    if (selectedDuelFilters.game === null && games?.length === 1) {
      setValue('game', games[0].id);
    }

    if (selectedDuelFilters.game !== null) {
      setValue('game', selectedDuelFilters.game);
    }

    if (selectedDuelFilters.game === 110 || selectedDuelFilters.game === 112) {
      setValue('entryFee', 0);
    } else {
      setValue('entryFee', defaultValues.entryFee);
    }
  }, [games, selectedDuelFilters.game]);

  useEffect(() => {
    if (selectedDuelFilters.platform !== null) {
      setValue('platform', selectedDuelFilters.platform);
    }
  }, [selectedDuelFilters.platform]);

  useEffect(() => {
    if (selectedDuelFilters.gameMode !== null) {
      setValue('gameMode', selectedDuelFilters.gameMode);
    }
  }, [selectedDuelFilters.gameMode]);

  const handleFormSubmit = async (/* data: FormType */) => {
    sendGAEvent({
      category: 'Arena',
      event: 'Click Quick Duel CTA',
      label: 'Quick Duel',
      value: getValues('entryFee'),
    });
    setIsModalOpen(true);
  };

  return games === undefined || user === null ? null : (
    <>
      <Modal
        actions={
          <>
            <Button
              disabled={isModalActionDisabled}
              onClick={() => setIsModalOpen(false)}
              type='button'
              variant='secondary'
            >
              <FormattedMessage defaultMessage='Cancel' />
            </Button>

            <Button
              disabled={isModalActionDisabled}
              onClick={async () => {
                // this is here only as a type guard
                if (hydratedGameMode === null) return;

                setIsModalActionDisabled(true);

                if (getValues('requiredAccountField') !== null) {
                  const requiredAccountFieldResponse = await api.patch(
                    '/users/me/',
                    {
                      [hydratedGameMode.requiredAccountField]: getValues(
                        'requiredAccountField',
                      ),
                    },
                  );

                  const requiredAccountFieldResponseJson =
                    await requiredAccountFieldResponse.json();

                  if (!requiredAccountFieldResponse.ok) {
                    // patch failed, let's not continue.
                    return;
                  }

                  await mutate('/users/me/', requiredAccountFieldResponseJson);
                }

                const payload = getValues();

                delete payload.requiredAccountField;

                const response = await api.post(
                  '/duels/get_or_create/',
                  payload,
                );
                const responseJson = await response.json();

                if (response.ok) {
                  await router.push(`/duels/duel?id=${responseJson.id}`);
                } else {
                  if (responseJson?.entryFee) {
                    setError('entryFee', {
                      type: 'server',
                      message: responseJson?.entryFee[0],
                    });
                  }

                  setServerError(responseJson);
                }

                setIsModalOpen(false);
                setIsModalActionDisabled(false);
              }}
              type='button'
            >
              <FormattedMessage defaultMessage='Find Duel' />
            </Button>
          </>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <FormattedMessage defaultMessage="You will join a duel based on your rating or create a new duel if there aren't any open. Continue?" />
      </Modal>

      {isSubmitting && <ActivityIndicator takeOver />}

      <section {...props} className={classNames(className, styles.quickDuel)}>
        <div className={styles.background} />

        <Container className={styles.container}>
          <div className={styles.header}>
            <Swords />

            <div className={styles.title}>
              <h2>
                <FormattedMessage defaultMessage='Let’s Join a Duel' />
              </h2>

              <p className={styles.description}>
                <FormattedMessage defaultMessage='Let’s find or join a duel with your choices.' />
              </p>
            </div>
          </div>

          <div className={styles.content}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <CommonError errors={serverError} />

              <fieldset>
                <FormSelect
                  control={control}
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Game' })}
                  name='game'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setValue('platform', null);
                    setValue('gameMode', null);

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      game: value,
                      gameMode: null,
                      platform: null,
                    });

                    if (value === 110 || value === 112) {
                      setValue('entryFee', 0);

                      setSelectedDuelFilters({
                        ...selectedDuelFilters,
                        game: value,
                        entryFee: 0,
                      });
                    }

                    sendGAEvent({
                      category: 'Arena',
                      event: 'Select Game',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={games?.map((game: any) => ({
                    label: game.name,
                    value: game.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <FormGroup>
                  <QuickDuelTournamentInfo />
                </FormGroup>

                <FormSelect
                  control={control}
                  disabled={platforms === undefined}
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Platform' })}
                  name='platform'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setValue('gameMode', null);

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      gameMode: null,
                      platform: value,
                    });

                    sendGAEvent({
                      category: 'Arena',
                      event: 'Select Platform',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={platforms?.map((option: any) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <FormSelect
                  control={control}
                  disabled={gameModes === undefined}
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Game Mode' })}
                  name='gameMode'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      gameMode: value,
                    });

                    sendGAEvent({
                      category: 'Arena',
                      event: 'Select Game Mode',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={gameModes?.map((option: any) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <FormInput
                  control={control}
                  errorobj={errors}
                  isVisible={
                    hydratedGameMode !== null &&
                    user![hydratedGameMode?.requiredAccountField] === null
                  }
                  label={
                    <RequiredAccountFieldDisplay
                      field={hydratedGameMode?.requiredAccountFieldStatus}
                    />
                  }
                  name='requiredAccountField'
                  size='large'
                  unregister={unregister}
                />

                <FormSelect
                  control={control}
                  disabled={
                    getValues('game') === 110 || getValues('game') === 112
                  }
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Entry Fee' })}
                  name='entryFee'
                  onChange={(opt: any) => {
                    const { value } = opt;

                    sendGAEvent({
                      category: 'Arena',
                      event: 'Select Entry Fee',
                      label: 'Quick Duel',
                      value,
                    });
                  }}
                  options={entryFeeChoices?.map((option: any) => ({
                    label: `${option} GA Coin`,
                    value: option,
                    disabled:
                      (getValues('game') === 110 ||
                        getValues('game') === 112) &&
                      option !== 0,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <div className={styles.sub}>
                  <div className={styles.info}>
                    <GameModeTooltip
                      gameModeId={selectedDuelFilters.gameMode}
                    />
                  </div>

                  <div className={styles.submit}>
                    <Button
                      className={styles.submitButton}
                      disabled={isSubmitting}
                      size='large'
                      type='submit'
                    >
                      <FormattedMessage defaultMessage='Find Duel' />
                    </Button>
                  </div>

                  <div className={styles.redirect}>
                    <Link
                      href={{
                        pathname: router.pathname,
                        query: {
                          ...router.query,
                          duelCreation: 'true',
                        },
                      }}
                      passHref
                    >
                      <a>
                        <FormattedMessage defaultMessage='or you can create custom duel' />
                      </a>
                    </Link>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </Container>
      </section>
    </>
  );
}
