import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import { useForm, UseFormReturn } from 'react-hook-form';
import { TypeOf } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommonError, FormGroup, yupCustom as yup } from '../Form';
import { AuthAndApiContext, ToastsContext } from '../../contexts';
import Button from '../Button';
import EmptyStateDisplay from '../EmptyStateDisplay';
import Modal from '../Modal';
import SettingsBlockedUser from '../SettingsBlockedUser';
import SettingsSectionTitle from '../SettingsSectionTitle';
import UserInput from '../UserInput';
import styles from './SettingsBlockUsers.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  className?: string;
};

export default function SettingsBlockUsers({ className, ...props }: Props) {
  const intl = useIntl();
  const { api, user } = useContext(AuthAndApiContext);
  const { addToast } = useContext(ToastsContext);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<any>(null);
  const [selectedUserValue, setSelectedUserValue] = useState<number | null>(
    null,
  );

  const validationSchema = yup.object({
    user: yup
      .number()
      .typeError(
        intl.formatMessage({
          defaultMessage: 'Please enter a valid username.',
        }),
      )
      .nullable(true)
      .required(),
  });

  type IBlockUsers = TypeOf<typeof validationSchema>;

  const defaultValues: IBlockUsers = {
    user: null,
  };

  const methods = useForm<IBlockUsers>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    unregister,
    control,
    formState: { errors },
  }: UseFormReturn<IBlockUsers> = methods;

  const { data: blockedUsers } = useSWR<BlockedUser[]>('/users/blocked/');

  const { data: userBlocked } = useSWR(
    selectedUserValue
      ? `/users/blocked/?blocked_user=${selectedUserValue}`
      : null,
  );

  const handleFormSubmit = async (/* data: any */) => {
    setServerError({});

    if (userBlocked === undefined) return;

    setIsDisabled(true);

    if (userBlocked.length !== 0) {
      setIsDisabled(false);

      addToast({
        content: intl.formatMessage({
          defaultMessage: 'This user is already blocked.',
        }),
        isPersistent: true,
        kind: 'warning',
      });

      return;
    }

    setIsModalOpen(true);
  };

  return (
    <section
      {...props}
      className={classNames(styles.settingsBlockUsers, className)}
    >
      <SettingsSectionTitle
        title={intl.formatMessage({ defaultMessage: 'Block Users' })}
      />

      <Modal
        actions={
          <>
            <Button
              onClick={() => setIsModalOpen(false)}
              type='button'
              variant='secondary'
            >
              <FormattedMessage defaultMessage='Close' />
            </Button>

            <Button
              onClick={async () => {
                setServerError({});

                const response = await api.post('/users/blocked/', {
                  blockedUser: selectedUserValue,
                });

                const responseJSON = await response.json();

                if (response.ok) {
                  await mutate('/users/blocked/');
                  await mutate(
                    `/users/blocked/?blocked_user=${selectedUserValue}`,
                  );

                  setValue('user', null);
                } else {
                  setServerError(responseJSON);
                }

                setIsDisabled(false);

                setIsModalOpen(false);
              }}
              type='button'
            >
              <FormattedMessage defaultMessage='Block User' />
            </Button>
          </>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <FormattedMessage defaultMessage='Are you sure you want to block this user?' />
      </Modal>

      <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
        <fieldset className={styles.fieldset} disabled={isDisabled}>
          <CommonError errors={serverError?.nonFieldErrors} />

          <FormGroup>
            <UserInput
              control={control}
              errorobj={errors}
              idExclude={user === null ? undefined : [user.id]}
              label={intl.formatMessage({ defaultMessage: 'Username' })}
              name='user'
              onChange={(userId) => {
                setSelectedUserValue(userId);
              }}
              required
              size='large'
              unregister={unregister}
              value={selectedUserValue || null}
            />
          </FormGroup>

          <Button fullWidth type='submit' variant='secondary'>
            <FormattedMessage defaultMessage='Block User' />
          </Button>
        </fieldset>
      </form>

      {blockedUsers === undefined ? null : (
        <>
          <div className={styles.header}>
            <h3>
              <FormattedMessage defaultMessage='Blocked Users' />
            </h3>
          </div>

          {blockedUsers.length === 0 ? (
            <EmptyStateDisplay
              className={styles.empty}
              kind='noUserGame'
              message={intl.formatMessage({
                defaultMessage: 'There are no blocked users.',
              })}
            />
          ) : (
            <div className={styles.blockedUsers}>
              {blockedUsers.map((u) => (
                <SettingsBlockedUser blockedUser={u} key={u.blockedUser} />
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
}
