import classNames from 'classnames';
import React from 'react';
import styles from './GACoin.module.css';
// import Coins from './coins.png';
import Coins from './gacoin.png';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['svg']> & {
  className?: string;
  grayscale?: boolean;
};

export default function GACoin({ className, grayscale }: Props) {
  return (
    <img
      alt='GA Coin'
      className={classNames(
        className,
        styles.gaCoin,
        grayscale && styles.grayscale,
      )}
      src={Coins.src}
    />
  );
}
