/* eslint-disable max-len */
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import Markdown from '../Markdown';
import styles from './LeaderboardTournamentRules.module.css';
import { ReactComponent as Plus } from './plus.svg';
import { LocalizationContext } from '../../contexts';
// import { TREASURE_MLID } from '../../helpers/statics';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    contents: string;
    id: number;
  }
>;

export default function LeaderboardTournamentRules({
  className,
  contents,
  id,
  ...props
}: Props) {
  const [isTournamentRulesOpen, setIsTournamentRulesOpen] =
    useState<boolean>(true);

  const { languageCode } = useContext(LocalizationContext);

  console.log('lang- tr_TR', languageCode);

  // const insertIndex =
  //   contents.indexOf('Bu yüzden yapmanız gereken tek şey düello oynamak.') +
  //   'Bu yüzden yapmanız gereken tek şey düello oynamak.'.length;

  // const newContent = `${contents.slice(
  //   0,
  //   insertIndex,
  // )}\n- <b>Mobile Legends: Bang Bang’de Silvanna hangi kahramanları counterlar?</b> ${contents.slice(
  //   insertIndex,
  // )}`;

  const newContent2 =
    languageCode !== 'tr'
      ? `<div>
    <h4>Complete the tasks to enter the tournament!</h4>
  
    <p>- In order for your duels to be counted in the ranking tournament and to be eligible for a prize, you must complete <a href=" https://gleam.io/SqW4f/gamer-arena-furkan-arabaci-clash-royale-tournament" alt="gamerarena">the tasks in this participation link.</a> The duels you play through Gamer Arena will be counted within the ranking tournament.</p>
  
    <p>- For the duels to be included in the ranking tournament, they must be initiated after the tournament start date and before the tournament end date.</p>
  
    <p>- Within the ranking tournament, only the first 3 duels you play with a player will be evaluated. Other duels played with the same player will not be considered within the tournament.</p>
  
    <h4>Tournament Results</h4>
  
    <p>- Rankings are determined based on the results of the duels played throughout the duration of the tournament.</p>
  
    <p>- After the ranking tournament ends, the top-ranked players will become the owners of the designated amount of prizes, provided they have completed the tasks in the participation link.</p>
  
    <h4>Checks</h4>
  
    <p>- After the tournament ends, players who are entitled to a prize undergo security and identity checks.</p>
  
    <p>- If it is determined that actions causing unfair competition, such as in-game cheating or dueling with fake accounts, have occurred during the security check, the player will be disqualified.</p>
  
    <p>- After the security check, an identity check is performed. For this, users are asked for a visual of their identification document. This visual must include the user’s name, surname, national identification number (TCKN), ID photo, and date of birth.</p>
  
    <p>- The identity information of a user who is over 18 must match the information provided to Gamer Arena for registration. Players under the age of 18 must obtain parental consent.</p>
  
    <p>- If the identity information mentioned above does not match the information provided by the player during the investigation, the player will be disqualified.</p>
  
    <p>- Throughout the process, if players who were previously entitled to a prize but were disqualified, their places will be filled by new players in accordance with the rankings. The process then restarts for the new winners.</p>
  </div>`
      : `<div><h4>Turnuvaya giriş için görevleri tamamla!</h4>
  <p>- Düellolarınızın sıralama turnuvasında sayılabilmesi ve ödül alabilmek için <a href="https://gleam.io/SqW4f/gamer-arena-furkan-arabaci-clash-royale-tournament">bu katılım linkindeki görevleri tamamlamanız gerekmektedir.</a> Gamer Arena üzerinden oynadığınız düellolar sıralama turnuvası kapsamında sayılacaktır.</p>
  <p>- Düelloların sıralama turnuvasına dahil olması için turnuva başlangıç tarihinden sonra ve turnuva bitiş tarihinden önce açılması gerekir.</p>
  <p>- Sıralama turnuvası kapsamında bir oyuncuyla yapacağınız ilk 3 düello değerlendirilir. Aynı oyuncuyla oynadığınız diğer düellolar turnuva kapsamında değerlendirilmez.</p>
  
  <h4>Turnuva Sonuçları</h4>
  <p>- Turnuva süresi boyunca oynanan düelloların sonuçlarına göre sıralamalar belirlenir.</p>
  <p>- Sıralama turnuvası bittikten sonra en iyi dereceli oyuncular katılım linkindeki görevleri tamamladıkları takdirde belirlenen miktardaki ödüllerin sahibi olurlar.</p>
  
  <h4>Kontroller</h4>
  <p>- Turnuvanın bitmesinin ardından ödül hak eden oyuncular güvenlik ve kimlik kontrolünden geçer.</p>
  <p>- Güvenlik kontrolünde oyun içi hile, sahte hesaplar arasında düello gibi haksız rekabete sebep olacak eylemler gerçekleştiği tespit edilirse oyuncu diskalifiye edilir.</p>
  <p>- Güvenlik kontrolünün ardından kimlik kontrolü yapılır. Bunun için kullanıcılardan kimlik belgesine ait bir görsel istenmektedir. Bu görsel üzerinde kullanıcıya ait isim, soyisim, TCKN, kimlik fotoğrafı ve doğum tarihi yer almalıdır.</p>
  <p>- +18 olan kullanıcının kimlik bilgilerinin Gamer Arena üzerinde kayıt için sağladığı bilgilerle uyuşması gerekmektedir. 18 yaşından küçük oyuncuların ise veli izni sağlamaları gerekir.</p>
  <p>- Eğer yukarıda bahsi geçen kimlik bilgileri, oyuncunun soruşturma kapsamında tarafımıza sağladığı bilgiler ile uyuşmaz ise oyuncu diskalifiye edilir.</p>
  <p>- Süreç boyunca daha öncesinde sıralamada ödül kazanmaya hakkı olup da diskalifiye edilen oyuncuların yerlerine, sıralama doğrultusunda yeni oyuncular yerleştirilir. Yeni kazananlar için süreç tekrardan işler.</p>
   </div>`;

  return (
    <section
      {...props}
      className={classNames(styles.leaderboardTournamentRules, className)}
    >
      <div
        className={classNames(
          styles.rules,
          isTournamentRulesOpen && styles.active,
        )}
      >
        <button
          className={styles.header}
          onClick={() => setIsTournamentRulesOpen((prev) => !prev)}
          type='button'
        >
          <p>
            <FormattedMessage defaultMessage='Tournament Rules' />
          </p>

          <Plus />
        </button>

        <AnimateHeight
          duration={150}
          height={isTournamentRulesOpen ? 'auto' : 0}
        >
          <div className={styles.content}>
            <Markdown className={styles.markdown}>
              {id === 791 ? newContent2 : contents}
            </Markdown>
          </div>
        </AnimateHeight>
      </div>
    </section>
  );
}
