/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { Input } from '../Input';
import { FieldErrorMessage } from '../FormErrorMessage';
import { FormGroup } from '../FormGroup';

export type FormInputProps = {
  name: string;
  label?: React.ReactNode;
  size?: FormComponentSize;
  inputClass?: string;
  type?: string;
  defaultValue?: string;
  placeholder?: string;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: any) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  unregister: any; // TODO: type
  errorobj: any;
  autoComplete?: string;
  control: any;
  value?: string | number;
  isVisible?: boolean;
  ref?: any;
  formGroupClassName?: string;
  maxlength?: number;
};

export const FormInput: FC<FormInputProps> = ({
  className,
  name,
  label,
  onBlur,
  onFocus,
  onChange,
  autoComplete,
  errorobj,
  size = 'medium',
  placeholder,
  control,
  unregister,
  maxlength,
  type = 'text',
  inputClass,
  isVisible = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: val,
  formGroupClassName,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible((prevValue) => {
      prevValue !== isVisible && !isVisible && unregister(name);
      return isVisible;
    });
  }, [isVisible]);

  let isError = false;
  let errorMessage = '';
  if (errorobj && Object.prototype.hasOwnProperty.call(errorobj, name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  // @ts-ignore
  return visible ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: _onChange, value } }) => (
        <FormGroup className={classNames(formGroupClassName)}>
          <Input
            autoComplete={autoComplete}
            className={className}
            errorobj={errorMessage}
            inputClass={inputClass}
            label={label}
            maxlength={maxlength}
            name={name}
            onBlur={onBlur}
            onChange={(e) => {
              const {
                target: { value: inputValue },
              } = e;

              onChange && onChange(inputValue);
              _onChange(inputValue);
            }}
            onFocus={onFocus}
            placeholder={placeholder}
            size={size}
            type={type}
            value={value}
            {...otherProps}
          />

          {isError && <FieldErrorMessage error={errorMessage} />}
        </FormGroup>
      )}
    />
  ) : null;
};
