import classNames from 'classnames';
import React from 'react';
import { useRouter } from 'next/router';
import styles from './LayoutSection.module.css';
import LandingSignUpButton from '../Landing/LandingSignUpButton';

type ButtonObj = {
  text: any;
  href?: string;
};

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    title?: any;
    subtitle?: any;
    image?: any;
    button?: ButtonObj;
    customElementsLeft?: any;
    customElementsRight?: any;
    columnOneStyle?: any;
    columnTwoStyle?: any;
    turnDirection?: boolean;
  }
>;

export default function LayoutSection({
  className,
  title,
  subtitle,
  image,
  button,
  customElementsLeft,
  customElementsRight,
  turnDirection,
  columnOneStyle,
  columnTwoStyle,
  ...props
}: Props) {
  const router = useRouter();
  return (
    <div
      {...props}
      className={classNames(
        styles.section,
        turnDirection && styles.turnElementsOver,
        className,
      )}
    >
      <div className={classNames(styles.columnOne, columnOneStyle)}>
        {customElementsLeft ? (
          <>{customElementsLeft}</>
        ) : (
          <div className={styles.textPart}>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>
            {button && (
              <LandingSignUpButton
                content={button.text}
                href={`${router.pathname}/?action=Hero`}
              />
            )}
          </div>
        )}
      </div>
      <div className={classNames(styles.columnTwo, columnTwoStyle)}>
        {customElementsRight ? (
          <>{customElementsRight}</>
        ) : (
          image && (
            <div className={styles.visualPart}>
              <img alt='Gamer Arena' src={image.src} />
            </div>
          )
        )}
      </div>
    </div>
  );
}
