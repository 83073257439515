import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
// import { AuthAndApiContext } from '../../contexts';
import { ReactComponent as Equality } from './equality.svg';
import styles from './WithdrawCurrencyRate.module.css';

interface WithdrawItem {
  _id: string;
  commission: {
    $numberDecimal: string;
  };
  balance: {
    $numberDecimal: string;
  };
  exchangeRate: {
    $numberDecimal: string;
  };
  __v: number;
}

// Update the Props type to specify the correct type for withdraws
type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    amount: number | null;
    paymentMethod: string | null;
    region: Region;
    withdraws: WithdrawItem[]; // Specify the type here
  }
>;

export default function WithdrawCurrencyRate({
  amount,
  className,
  paymentMethod,
  region,
  withdraws,
  ...props
}: Props) {
  // const { user } = useContext(AuthAndApiContext);
  // const base = useMemo(
  //   () =>
  //     user?.withdrawThreshold
  //       ? user.withdrawThreshold
  //       : region.withdrawThreshold,
  //   [],
  // );
  const base = 0;
  const transactionFee = useMemo<number>(() => {
    if (paymentMethod === 'icrypex') {
      return 0;
    }
    if (amount === null || amount <= 0) return 0;

    const matchingWithdraw = withdraws.find(
      (item) => Number(item?.balance?.$numberDecimal) === amount,
    );

    if (matchingWithdraw) {
      const commission = Number(matchingWithdraw.commission.$numberDecimal);
      return commission * amount;
    }

    return 0; // No matching balance found
  }, [amount, paymentMethod, withdraws]);

  return (
    <section
      {...props}
      className={classNames(styles.withdrawCurrencyRate, className)}
    >
      <div className={styles.inline}>
        <div className={styles.info}>
          <p className={styles.muted}>
            <FormattedMessage defaultMessage='Withdraw Amount' />
          </p>

          <h3 className={styles.amount}>
            <FormattedMessage
              defaultMessage='{amount} GA Coin'
              values={{
                amount:
                  amount === null || amount <= 0
                    ? base
                    : Math.round(amount * 100) / 100,
              }}
            />
          </h3>
        </div>

        <div className={styles.separator} />

        <div className={styles.info}>
          <p className={styles.muted}>
            <FormattedMessage defaultMessage='Transaction Fee' />
          </p>

          <h3 className={styles.amount}>
            <FormattedMessage
              defaultMessage='{amount} GA Coin'
              values={{ amount: Math.round(transactionFee * 100) / 100 }}
            />
          </h3>
        </div>
      </div>

      <div className={styles.info}>
        <p className={styles.muted}>
          <FormattedMessage defaultMessage='Net Amount' />
        </p>

        <h3 className={styles.amount}>
          <FormattedMessage
            defaultMessage='{amount} GA Coin'
            values={{
              amount:
                amount === null || amount <= 0
                  ? Math.round((base - transactionFee) * 100) / 100
                  : Math.round((amount - transactionFee) * 100) / 100,
            }}
          />
          {' = '}
          {amount === null || amount <= 0
            ? Math.round((base - transactionFee) * region.exchangeRate * 100) /
              100
            : Math.round(
                (amount - transactionFee) * region.exchangeRate * 100,
              ) / 100}{' '}
          {region.currencyCode}
        </h3>
      </div>

      <div className={styles.equality}>
        <Equality />
      </div>
    </section>
  );
}
