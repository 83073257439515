import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LandingCounter from '../LandingCounter';
import styles from './LandingNumbers.module.css';

type Numbers = {
  value: number;
  title?: any;
  subtitle?: any;
  letter: any;
};

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    numbers: Numbers[];
  }
>;

export default function LandingNumbers({
  className,
  numbers,
  ...props
}: Props) {
  return (
    <div {...props} className={classNames(styles.landingNumbers, className)}>
      <p className={styles.title}>
        <FormattedMessage defaultMessage='How Much Played?' />
      </p>
      <div className={styles.numbers}>
        {numbers.map((number) => (
          <div className={styles.number} key={number.value}>
            <div className={styles.title}>
              <LandingCounter letter={number.letter} value={number.value} />
              <p className={styles.main}>{number.title}</p>
              <p className={styles.subtitle}>{number.subtitle}</p>
            </div>
            <div className={styles.divider} />
          </div>
        ))}
      </div>
    </div>
  );
}
