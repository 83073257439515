import classNames from 'classnames';
import React, { memo, useMemo } from 'react';
import { ReactComponent as GoogleIcon } from './googleIcon.svg';
import { ReactComponent as AppleIcon } from './appleIcon.svg';
import styles from './LoginSocialGoogleButtons.module.css';

type SocialType = 'google' | 'apple';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    className?: string;
    text: string | React.ReactNode;
    socialType: SocialType;
  }
>;

const LoginSocialButtons = ({
  text,
  className,
  socialType,
  ...props
}: Props) => {
  const currentSocialType = useMemo<SocialType>(() => {
    if (socialType === 'apple') return 'apple';

    return 'google';
  }, [socialType]);

  const socialTypeIcon = useMemo<Record<SocialType, React.ReactNode>>(
    () => ({
      google: <GoogleIcon />,
      apple: <AppleIcon />,
    }),
    [],
  );

  return (
    <div className={classNames(styles.socialButton, className)} {...props}>
      {socialTypeIcon[currentSocialType]}
      <span className={styles.txt}>{text}</span>
    </div>
  );
};

export default memo(LoginSocialButtons);
