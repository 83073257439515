import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './FieldErrorMessage.module.css';
import { jpConfig } from '../../yup.configJp';

export interface ValidationError {
  key: string;
  values?: any;
}

export type FormErrorMessageProps = {
  error?: string | ValidationError;
};

export const FieldErrorMessage = ({ error }: FormErrorMessageProps) => {
  if (error === undefined) {
    return null;
  }

  console.log('error', error);

  if (typeof error === 'string') {
    return (
      <div className={classNames(styles.message, styles.error)}>{error}</div>
    );
  }

  const { key, values } = error;

  return (
    <div className={classNames(styles.message, styles.error)}>
      {(() => {
        switch (key) {
          case jpConfig.mixed.required.key:
            return (
              <FormattedMessage
                defaultMessage='This field is required.'
                id='validations.required'
              />
            );
          case jpConfig.string.email.key:
            return (
              <FormattedMessage
                defaultMessage='Email is invalid.'
                id='validations.email'
              />
            );
          case jpConfig.string.max(values?.max || 0).key:
            return (
              <FormattedMessage
                defaultMessage='Max {max} character.'
                id='validations.stringMax'
                values={{ max: values?.max }}
              />
            );
          case jpConfig.string.min(values?.min || 0).key:
            return (
              <FormattedMessage
                defaultMessage='Min {min} character.'
                id='validations.stringMin'
                values={{ min: values?.min }}
              />
            );
          case jpConfig.mixed.oneOf.key:
            return (
              <FormattedMessage
                defaultMessage='This field is required.'
                id='validations.required'
              />
            );
          case jpConfig.mixed.invalidType(values?.type).key:
            return (
              <FormattedMessage
                defaultMessage='Valid Type is {type}.'
                id='validations.invalidType'
                values={{ type: values?.type }}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};
