import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import firebase from 'firebase/app';
import { AuthAndApiContext, RegionContext } from '../../contexts';
import Checkbox from '../Checkbox';
import Errors from '../Errors';
import FormGroup from '../FormGroup';
import Gamerarena from './GamerArenaLogo.png';
// import IcrypexVerifier from '../IcrypexVerifier';
import RadioInput from '../RadioInput';
import { ReactComponent as Card } from './card.svg';
import styles from './DepositPaymentMethod.module.css';
import { ReactComponent as Paypal } from './paypal.svg';
import TextInput from '../TextInput';
import LoginSocialButtons from '../LoginSocialButtons';
import Button from '../Button';
import { ReactComponent as Down } from './down.svg';
import { ReactComponent as Up } from './up.svg';

// type IcrypexUserInfo = {
//   email: string | null;
//   phone: string | null;
//   firstName: string | null;
//   lastName: string | null;
//   identity: string | null;
//   isVerified: boolean | null;
//   smsPassword: string | null;
// };

// type IcrypexResponse = {
//   returnCode: string | null;
//   returnMessage: string | null;
//   gaValidation: Record<string, Array<string>> | null;
// };

type OzanpayInfo = {
  firstName: string | null;
  lastName: string | null;
};

type TournamentRule = {
  status: boolean | null;
};

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    errors: Record<string, Array<string>>;
    gaCoinAmount: number | null;
    // icrypexUserInfo: IcrypexUserInfo;
    // icrypexResponse: IcrypexResponse;
    ozanpayInfo: OzanpayInfo;
    paymentMethod: DepositProvider | null;
    termsAgreed: boolean;
    termsAgreedError: Array<string>;
    // setIcrypexUserInfo: (paymentMethod: Props['icrypexUserInfo']) => void;
    // setIcrypexResponse: (paymentMethod: Props['icrypexResponse']) => void;
    setOzanpayInfo: (paymentMethod: Props['ozanpayInfo']) => void;
    setPaymentMethod: React.Dispatch<
      React.SetStateAction<Props['paymentMethod']>
    >;
    setTermsAgreed: React.Dispatch<React.SetStateAction<Props['termsAgreed']>>;
    setTournamentRule: React.Dispatch<React.SetStateAction<TournamentRule>>;
  }
>;

export default function DepositPaymentMethod({
  className,
  errors,
  // gaCoinAmount,
  // icrypexUserInfo,
  // icrypexResponse,
  paymentMethod,
  // setIcrypexUserInfo,
  // setIcrypexResponse,
  ozanpayInfo,
  setOzanpayInfo,
  setPaymentMethod,
  setTermsAgreed,
  setTournamentRule,
  termsAgreed,
  termsAgreedError,
  ...props
}: Props) {
  const { region } = useContext(RegionContext);
  const { user } = useContext(AuthAndApiContext);
  const intl = useIntl();

  const [isGaApp, setisGaApp] = useState(false);
  const [isGA, setIsGA] = useState(false);
  const [tranStats, setTranStats] = useState([
    {
      _id: '',
      amount: {
        $numberDecimal: '',
      },
      status: 0,
      transaction: 0,
      channel: 0,
      date: '',
    },
  ]);
  const [loginResponse, setLoginResponse] = useState({
    nickname: '',
    wallet: '',
  });

  const [sliderValue, setSliderValue] = useState(
    Math.floor(Number(loginResponse.wallet) / 1000) * 1000 || 0,
  );

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  const secondApp = firebase.app('gaapp');
  const secondAuth = secondApp.auth();

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    setIsGA(true);
    return secondAuth.signInWithPopup(provider);
  };

  // Apple ile oturum açma işlemi için
  const signInWithApple = () => {
    const appleProvider = new firebase.auth.OAuthProvider('apple.com');
    setIsGA(true);
    return secondAuth.signInWithPopup(appleProvider);
  };

  function userWallet(token: string) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_MOBILE_URL}api/profile/myWallet`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        if (response.status === 401) {
          secondAuth.signOut().then(() => {
            // setLoginResponse(null);
            // setWalletLogin(null);
            localStorage.clear();
          });
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setLoginResponse((prev) => ({
          ...prev,
          wallet: res?.[0]?.count?.$numberDecimal,
        }));

        setSliderValue(
          Math.floor(Number(res?.[0]?.count?.$numberDecimal) / 1000) * 1000 ||
            0,
        );
        console.log('setLoginResponsekısmı', res);
      })
      .catch((error) => console.log('error', error));
  }

  const localStorageBasicTokenKey = process.env
    .NEXT_PUBLIC_GAMERARENA_LOCAL_STORAGE_BASIC_TOKEN_KEY as string;
  const tokens = window.localStorage.getItem(
    localStorageBasicTokenKey,
  ) as string;

  function socialLoginWallet(token: string) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    const raw = JSON.stringify({
      token,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/transaction/connectAccount`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        console.log(res);
        setLoginResponse(res);
        userWallet(res?.token);
        window.localStorage.setItem('Token', res?.token);
        getTransactionStats();
      })
      .catch((error) => console.log('error', error));
  }

  const tokenSpor = window.localStorage.getItem('Token') as string;

  function toPlatform() {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    const raw = JSON.stringify({
      token: tokenSpor,
      amount: sliderValue,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/transaction/toPlatform`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        console.log('ok', res);
        window.location.replace('https://gamerarena.com/wallet');
      })
      .catch((error) => console.log('error', error));
  }
  function getTransactionStats() {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    myHeaders.append('x-access-token', tokenSpor);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_MOBILE_URL}api/profile/getTransactionStats`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setTranStats(res);
        console.log(tranStats);
      })
      .catch((error) => console.log('error', error));
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isGA) {
      const unsubscribe = secondAuth.onAuthStateChanged((users) => {
        console.log('usewr2', users);
        // auth.signOut()
        if (users) {
          users.getIdToken().then((token) => {
            console.log('ali veli', token);

            socialLoginWallet(token);
          });
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [isGA]);

  return region === undefined ? null : (
    <section
      {...props}
      className={classNames(styles.depositPaymentMethod, className)}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>
          <FormattedMessage defaultMessage='Choose Payment Method' />
        </h3>

        <p className={styles.muted}>
          <FormattedMessage defaultMessage='You will be directed to Wirecard to add your payment information.' />
        </p>
      </div>

      <div className={styles.wrapper}>
        <FormGroup className={styles.formGroup}>
          {region.depositProviders.includes('wirecard_pos') && (
            <>
              <RadioInput
                checked={paymentMethod === 'wirecard_pos'}
                className={styles.radio}
                id='wirecard_pos'
                label={
                  <div className={styles.content}>
                    <Card />

                    <div className={styles.info}>
                      <p className={styles.muted}>
                        <FormattedMessage defaultMessage='Payment Method' />
                      </p>

                      <p>
                        <FormattedMessage defaultMessage='Credit Card or Bank Card' />
                      </p>
                    </div>
                  </div>
                }
                onChange={(e) => {
                  setPaymentMethod(e.target.value as DepositProvider);
                  setTournamentRule({ status: false });
                  setisGaApp(false);
                }}
                value='wirecard_pos'
              />

              <div className={styles.icrypexInputs}>
                <FormGroup>
                  <TextInput
                    // disabled={icrypexUserInfo.isVerified || false}
                    id='icrypex-wallet-name'
                    label={intl.formatMessage({ defaultMessage: 'First Name' })}
                    name='name'
                    onChange={(e) =>
                      setOzanpayInfo({
                        ...ozanpayInfo,
                        firstName: e.target.value,
                      })
                    }
                    required={paymentMethod === 'wirecard_pos'}
                    size='large'
                    value={ozanpayInfo.firstName || ''}
                  />
                </FormGroup>

                <FormGroup>
                  <TextInput
                    id='icrypex-wallet-lastname'
                    label={intl.formatMessage({ defaultMessage: 'Last Name' })}
                    name='lastname'
                    onChange={(e) =>
                      setOzanpayInfo({
                        ...ozanpayInfo,
                        lastName: e.target.value,
                      })
                    }
                    required={paymentMethod === 'wirecard_pos'}
                    size='large'
                    value={ozanpayInfo.lastName || ''}
                  />
                </FormGroup>
              </div>
            </>
          )}

          {/* {region.depositProviders.includes('icrypex_deposit') && (
            <div>
              <RadioInput
                checked={paymentMethod === 'icrypex_deposit'}
                className={styles.radio}
                id='icrypex'
                label={
                  <div className={styles.content}>
                    <Card />

                    <div className={styles.info}>
                      <p className={styles.muted}>
                        <FormattedMessage defaultMessage='Payment Method' />
                      </p>

                      <p>
                        <FormattedMessage defaultMessage='Icrypex' />
                      </p>
                    </div>
                  </div>
                }
                onChange={(e) =>
                  setPaymentMethod(e.target.value as DepositProvider)
                }
                value='icrypex_deposit'
              />

              {paymentMethod === 'icrypex_deposit' && (
                <IcrypexVerifier
                  errors={errors}
                  gaCoinAmount={gaCoinAmount}
                  icrypexResponse={icrypexResponse}
                  icrypexUserInfo={icrypexUserInfo}
                  paymentMethod={paymentMethod}
                  setIcrypexResponse={setIcrypexResponse}
                  setIcrypexUserInfo={setIcrypexUserInfo}
                />
              )}
            </div>
          )} */}

          {region.depositProviders.includes('paypal_checkout') && (
            <RadioInput
              checked={paymentMethod === 'paypal_checkout'}
              className={styles.radio}
              id='paypal_checkout'
              label={
                <div className={styles.content}>
                  <Paypal className={styles.paypal} />

                  <div className={styles.info}>
                    <p className={styles.muted}>
                      <FormattedMessage defaultMessage='Payment Method' />
                    </p>

                    <p>PayPal</p>
                  </div>
                </div>
              }
              onChange={(e) =>
                setPaymentMethod(e.target.value as DepositProvider)
              }
              value='paypal_checkout'
            />
          )}
        </FormGroup>

        {/* GAMER ARENA APP KISMI  */}
        <div>
          <RadioInput
            checked={isGaApp}
            className={styles.radio}
            id='gamerarenaPayment'
            label={
              <div className={styles.content}>
                <img
                  alt='gamerarena'
                  src={Gamerarena.src}
                  style={{ width: '20%' }}
                />
              </div>
            }
            onChange={() => {
              setisGaApp(true);
              setPaymentMethod(null);
              setTournamentRule({ status: false });
            }}
            required
            value='gamerarena_payment'
          />
        </div>

        {isGaApp && loginResponse?.nickname.length === 0 && (
          <>
            <LoginSocialButtons
              onClick={() => signInWithGoogle()}
              socialType='google'
              text={<FormattedMessage defaultMessage='Continue with Google' />}
            />

            <LoginSocialButtons
              onClick={() => signInWithApple()}
              socialType='apple'
              text={<FormattedMessage defaultMessage='Continue with Apple' />}
            />
          </>
        )}
        {loginResponse?.nickname.length > 0 && (
          <>
            {/* GA PLATFORM */}
            <div
              style={{
                background:
                  'linear-gradient(112.1deg, #8C49E2 17.84%, #EB2A44 103.4%)',
                borderRadius: '6px',
                height: '120px',
                width: '100%',
                marginTop: '2%',
              }}
            >
              <div style={{ paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <p
                    style={{
                      fontSize: '15px',
                      color: '#FFFFFF',
                      padding: 1,
                    }}
                  >
                    GAMER ARENA PLATFORM
                  </p>
                </div>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          opacity: 0.7,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                      >
                        <FormattedMessage defaultMessage='Username' /> : @
                        {user?.fullName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: 0, paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          fontSize: '20px',
                          color: '#FFFFFF',
                          padding: 1,
                        }}
                      >
                        {user.balance} GA Coin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FormattedMessage defaultMessage='The min limit for transferring GA Coins from app to platform is 1000 GA Coins.' />

            <div style={{ marginTop: '2%', marginBottom: '2%' }}>
              <input
                id='mySlider'
                max={
                  Math.floor(Number(loginResponse.wallet) / 1000) * 1000 || 0
                }
                min={1000}
                onChange={handleSliderChange}
                step={1000}
                style={{
                  width: '100%',
                  height: '5px',
                  background: '#ccc',
                  outline: 'none',
                  opacity: '0.7',
                  transition: 'opacity .2s',
                }}
                type='range'
                value={sliderValue}
              />
              <span
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  display: 'block',
                }}
              >
                {sliderValue}
              </span>
            </div>

            {/* GA APP */}
            <div
              style={{
                background:
                  'linear-gradient(112.1deg, #EB2A44 17.84%, #8C49E2 103.4%)',
                borderRadius: '6px',
                height: '120px',
              }}
            >
              <div style={{ paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <p
                    style={{
                      fontSize: '15px',
                      color: '#FFFFFF',
                      padding: 1,
                    }}
                  >
                    GAMER ARENA APP
                  </p>
                </div>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          opacity: 0.7,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                      >
                        <FormattedMessage defaultMessage='Username' /> : @
                        {loginResponse?.nickname}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: 0, paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          fontSize: '20px',
                          color: '#FFFFFF',
                          padding: 1,
                        }}
                      >
                        {Number(loginResponse?.wallet).toFixed(2) ?? '-'} GA
                        Coin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* ----- */}

        <div className={styles.terms}>
          <FormGroup className={styles.formGroup} error={termsAgreedError}>
            <Checkbox
              checked={termsAgreed}
              className={styles.conditions}
              id='checkbox-deposit-terms-agreed'
              label={
                <p className={styles.label}>
                  <FormattedMessage defaultMessage='I confirm that I’m 18 years of age and older. By clicking the button below I accept all the legal responsibilities.' />
                </p>
              }
              name='depositTermsAgreed'
              onChange={(e) => setTermsAgreed(e.target.checked)}
              required
            />
          </FormGroup>
        </div>
        <Errors errors={errors.provider} />
      </div>

      {loginResponse?.nickname.length > 0 && (
        <Button
          disabled={Number(loginResponse.wallet) < 1000}
          onClick={() => toPlatform()}
          size='large'
          type='submit'
          variant='green'
        >
          <FormattedMessage defaultMessage='Deposit' />
        </Button>
      )}
      {loginResponse?.nickname.length > 0 && (
        <>
          <FormattedMessage defaultMessage='Your Withdrawal Requests in Progress' />
          {tranStats.map((item, indeks) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.item} key={indeks}>
              <div className={styles.icon}>
                {item.transaction === 0 ? <Down /> : <Up />}
              </div>

              <div className={styles.details}>
                <p className={styles.date}>
                  {dayjs(item.date).format('D MMMM')}
                </p>

                {/* <p className={styles.kind}>{transactionKinds[t.kind]}</p> */}
              </div>

              <div className={styles.amount}>
                {item.transaction === 0 ? '-' : '+'}
                {item.amount.$numberDecimal}{' '}
                <FormattedMessage defaultMessage='GA Coins' />
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
}
