import { useEffect, useRef } from 'react';

export default function useInterval(
  callback: any,
  delay: any,
  isInViewPort: any,
) {
  const savedCallback = useRef<typeof callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id: any = null;
    if (isInViewPort) {
      id = setInterval(() => {
        savedCallback.current();
      }, delay);
    }

    return () => {
      clearInterval(id);
    };
  }, [delay, isInViewPort]);
}
