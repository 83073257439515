import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useClickAway } from 'react-use';
import { BreakpointContext, FilterSortContext } from '../../contexts';
import { useKeyDown } from '../../hooks';
import BackgroundShade from '../BackgroundShade';
import Button from '../Button';
import FilterSortForm from '../FilterSortForm';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Filter } from './filter.svg';
import styles from './FilterSort.module.css';
import { ReactComponent as RollUp } from './rollUp.svg';
import { ReactComponent as Toggle } from './toggle.svg';

// import { ReactComponent as Sort } from './sort.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    className?: string;
    isFilterSortSidebarOpen?: boolean;
    type?: 'fixedBottom' | 'fixedTop' | 'vertical' | 'popover';
    forComponent?: string;
    showPlatformField?: boolean;
  }
>;

export default function FilterSort({
  className,
  type = 'fixedBottom',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forComponent,
  showPlatformField = false,
  ...props
}: Props) {
  // const { breakpoint } = useContext(BreakpointContext);
  const [isFilterSortSidebarOpen, setIsFilterSortSidebarOpen] =
    useState<boolean>(true);
  const {
    // orderingOptions,
    selectedDuelFilters,
    selectedDuelStatus,
    selectedOrderingOption,
    toggleFilter,
    setSelectedDuelFilters,
    setSelectedDuelStatus,
    setSelectedOrderingOption,
    setToggleFilter,
    setFilterCount,
  } = useContext(FilterSortContext);
  const { breakpoint } = useContext(BreakpointContext);
  const content = useRef<HTMLDivElement>(null);

  const hasNoFilters =
    selectedDuelStatus.length === 0 &&
    selectedDuelFilters.game === null &&
    selectedDuelFilters.gameMode === null &&
    selectedDuelFilters.platform === null &&
    selectedOrderingOption === null;

  const isBigScreen = useMemo<boolean>(
    () => ['xl', 'xxl'].includes(breakpoint),
    [breakpoint],
  );

  const filterCountMemo = useMemo<number | null>(() => {
    const temp =
      [
        selectedDuelFilters.game,
        selectedDuelFilters.gameMode,
        selectedDuelFilters.platform,
      ].filter((f) => f !== null).length +
      (selectedDuelStatus.length > 0 ? 1 : 0);
    const result = temp > 0 ? temp : null;

    return result;
  }, [selectedDuelStatus, selectedDuelFilters]);

  useEffect(() => {
    setFilterCount(filterCountMemo);
  }, [filterCountMemo]);

  useEffect(() => {
    const hasFilterSortClassName = 'has-filter-sort';

    if (type !== 'vertical' && toggleFilter) {
      document.body.classList.add(hasFilterSortClassName);
    } else {
      document.body.classList.remove(hasFilterSortClassName);
    }

    return () => {
      document.body.classList.remove(hasFilterSortClassName);
    };
  }, [toggleFilter, type]);

  const toogleFilter = () => {
    setToggleFilter(!toggleFilter);
  };

  useEffect(() => {
    if (type === 'vertical') {
      toogleFilter();
    }
  }, [type]);

  useClickAway(content, () => {
    if (toggleFilter) {
      toogleFilter();
    }
  });

  useKeyDown(() => {
    if (toggleFilter) {
      toogleFilter();
    }
  }, ['escape']);

  const fixedBottom = (
    <div className={styles.wrapper}>
      {/* <button
        className={styles.buttonContainer}
        onClick={() => setIsOpen((prev) => !prev)}
        type='button'
      >
        <div className={styles.headerButton}>
          <div className={styles.icon}>
            <Filter />

            {filterCountMemo !== null && <div className={styles.filledIndicator} />}
          </div>

          <FormattedMessage defaultMessage='Filter' />

          {filterCountMemo !== null && ` (${filterCountMemo})`}
        </div>

        <div
          className={classNames(
            styles.headerButton,
            breakpoint !== 'xs' &&
              selectedOrderingOption !== null &&
              styles.withDetail,
          )}
        >
          <div className={styles.icon}>
            <Sort />

            {selectedOrderingOption !== null && (
              <div className={styles.filledIndicator} />
            )}
          </div>

          <FormattedMessage defaultMessage='Sort' />

          {breakpoint !== 'xs' && selectedOrderingOption !== null && (
            <span className={styles.detail}>
              {
                orderingOptions.find((o) => o.value === selectedOrderingOption)
                  ?.label
              }
            </span>
          )}
        </div>
      </button>
 */}
      <div className={styles.body} ref={content}>
        <TransitionGroup component={null}>
          {toggleFilter && (
            <CSSTransition timeout={300}>
              <div className={styles.filterContainer}>
                <div className={styles.close}>
                  <button
                    className={styles.closeButton}
                    onClick={toogleFilter}
                    type='button'
                  >
                    <Close />
                  </button>
                </div>

                <div className={styles.filterForm}>
                  <FilterSortForm showPlatformField={showPlatformField} />

                  <div className={styles.actions}>
                    <Button
                      className={styles.filterButton}
                      disabled={hasNoFilters}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();

                        toogleFilter();
                      }}
                      size='large'
                      variant={hasNoFilters ? 'secondary' : 'primary'}
                    >
                      <FormattedMessage defaultMessage='Show Results' />
                    </Button>

                    {hasNoFilters ? null : (
                      <button
                        className={styles.clearAll}
                        onClick={(e) => {
                          e.preventDefault();

                          setSelectedDuelStatus([]);

                          setSelectedDuelFilters({
                            ...selectedDuelFilters,
                            game: null,
                            gameMode: null,
                            platform: null,
                          });

                          setSelectedOrderingOption(null);
                        }}
                        type='button'
                      >
                        <FormattedMessage defaultMessage='Clear All' />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );

  const fixedTop = (
    <div className={styles.wrapper}>
      <button
        className={styles.buttonContainer}
        onClick={toogleFilter}
        type='button'
      >
        <div className={styles.headerButton}>
          <div className={styles.icon}>
            <Filter />

            {filterCountMemo !== null && (
              <div className={styles.filledIndicator} />
            )}
          </div>

          <FormattedMessage defaultMessage='Filter' />

          {filterCountMemo !== null && ` (${filterCountMemo})`}
        </div>

        {/* <div
          className={classNames(
            styles.headerButton,
            breakpoint !== 'xs' &&
              selectedOrderingOption !== null &&
              styles.withDetail,
          )}
        >
          <div className={styles.icon}>
            <Sort />

            {selectedOrderingOption !== null && (
              <div className={styles.filledIndicator} />
            )}
          </div>

          <FormattedMessage defaultMessage='Sort' />

          {breakpoint !== 'xs' && selectedOrderingOption !== null && (
            <span className={styles.detail}>
              {
                orderingOptions.find((o) => o.value === selectedOrderingOption)
                  ?.label
              }
            </span>
          )}
        </div> */}
      </button>

      <AnimateHeight duration={150} height={toggleFilter ? 'auto' : 0}>
        <div className={styles.dropdown} ref={content}>
          <div className={styles.filterContainer}>
            <div className={styles.filterForm}>
              <FilterSortForm showPlatformField={showPlatformField} />

              <div className={styles.actions}>
                {!hasNoFilters && (
                  <button
                    className={styles.clearAll}
                    onClick={(e) => {
                      e.preventDefault();

                      setSelectedDuelStatus([]);

                      setSelectedDuelFilters({
                        ...selectedDuelFilters,
                        game: null,
                        gameMode: null,
                        platform: null,
                      });

                      setSelectedOrderingOption(null);
                    }}
                    type='button'
                  >
                    <FormattedMessage defaultMessage='Clear All' />
                  </button>
                )}

                <button
                  className={styles.rollUp}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();

                    toogleFilter();
                  }}
                  type='button'
                >
                  <RollUp />
                </button>
              </div>
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );

  const verticalFilterSort = (
    <div
      className={classNames(
        styles.verticalFilterSort,
        isBigScreen &&
          type === 'vertical' &&
          !isFilterSortSidebarOpen &&
          styles.closed,
      )}
    >
      {isBigScreen && (
        <>
          <button
            className={classNames(
              styles.toggle,
              isFilterSortSidebarOpen && styles.open,
            )}
            onClick={() => setIsFilterSortSidebarOpen((prev) => !prev)}
            type='button'
          >
            {isFilterSortSidebarOpen ? (
              <Toggle className={styles.opened} />
            ) : (
              <>
                <Toggle className={styles.closed} />

                <FormattedMessage defaultMessage='Filter' />

                {filterCountMemo !== null && (
                  <span className={styles.drawerFilterCount}>
                    {filterCountMemo}
                  </span>
                )}
              </>
            )}
          </button>
        </>
      )}
      <TransitionGroup component={null}>
        {isFilterSortSidebarOpen && (
          <CSSTransition timeout={300}>
            <div className={styles.body}>
              <div className={styles.filterForm}>
                <FilterSortForm
                  showPlatformField={showPlatformField}
                  vertical
                />
              </div>

              <div className={styles.actions}>
                {!hasNoFilters && (
                  <button
                    className={styles.clearAll}
                    onClick={(e) => {
                      e.preventDefault();

                      setSelectedDuelStatus([]);

                      setSelectedDuelFilters({
                        ...selectedDuelFilters,
                        game: null,
                        gameMode: null,
                        platform: null,
                      });

                      setSelectedOrderingOption(null);
                    }}
                    type='button'
                  >
                    <FormattedMessage defaultMessage='Clear All' />
                  </button>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );

  const popoverFilterSort = (
    <TransitionGroup>
      {toggleFilter && (
        <CSSTransition timeout={300}>
          <div className={styles.popoverFilterSort} ref={content}>
            <div className={styles.body}>
              <div className={styles.filterForm}>
                <FilterSortForm showPlatformField={showPlatformField} />

                <div className={styles.actions}>
                  {!hasNoFilters && (
                    <button
                      className={styles.clearAll}
                      onClick={(e) => {
                        e.preventDefault();

                        setSelectedDuelStatus([]);

                        setSelectedDuelFilters({
                          ...selectedDuelFilters,
                          game: null,
                          gameMode: null,
                          platform: null,
                        });

                        setSelectedOrderingOption(null);
                      }}
                      type='button'
                    >
                      <FormattedMessage defaultMessage='Clear All' />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  );

  return (
    <div
      {...props}
      className={classNames(
        styles.filterSort,
        className,
        isBigScreen &&
          type === 'vertical' && [
            styles.shrink,
            styles.verticalFilterSortContainer,
          ],
      )}
    >
      {!isBigScreen && type !== 'vertical' && (
        <BackgroundShade isVisible={toggleFilter} />
      )}

      {type === 'fixedBottom' && fixedBottom}
      {type === 'fixedTop' && fixedTop}
      {type === 'vertical' && verticalFilterSort}
      {type === 'popover' && popoverFilterSort}
    </div>
  );
}
