import classNames from 'classnames';
import React from 'react';
import styles from './InlineLabel.module.css';

type Size = 'large' | 'medium' | 'small';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  className?: string;
  image?: string | undefined;
  imageRadius?: boolean;
  size?: Size;
};

export default function InlineLabel({
  children,
  className,
  image,
  imageRadius = true,
  size = 'medium',
  ...props
}: Props) {
  const sizeStyle: Record<Size, string> = {
    large: styles.sizeLarge,
    medium: styles.sizeMedium,
    small: styles.sizeSmall,
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <div
      {...props}
      className={classNames(
        styles.inlineLabel,
        image !== undefined && styles.withImage,
        className,
        sizeStyle[size],
      )}
    >
      {image !== undefined && (
        <img
          alt={(children || '').toString()}
          className={classNames(imageRadius && styles.imageRadius)}
          src={image}
        />
      )}

      {children}
    </div>
  );
}
