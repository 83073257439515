import * as yup from 'yup';
/* import { RequiredStringSchema } from 'yup/es/string';
import { AnyObject, Maybe } from 'yup/lib/types'; */

import { setLocale } from 'yup';
import { jpConfig } from './yup.configJp';

setLocale(jpConfig);

/* addMethod<StringSchema>(
  string,
  'customEmailOrUsername',
  function (appendStr: string) {
    return this.test(value, ctx) {
      if (!value) {
        return ctx.createError({
          path,
          message: 'testaaa',
        });
      }

      if (value.length < 3) {
        return ctx.createError({
          path,
          message: 'test2',
        });
      }

      return true;
    }
  },
);

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    customEmailOrUsername(): RequiredStringSchema<TType, TContext>;
  }
} */

export const yupCustom = yup;
