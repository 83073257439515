import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import styles from './Button.module.css';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Swords } from './swords.svg';
import { ReactComponent as Go } from './go.svg';
import ActivityIndicator from '../ActivityIndicator';

type Icon = 'go' | 'send' | 'swords';
type Variant = 'green' | 'primary' | 'secondary' | 'invalid';

type CommonProps = {
  children?: React.ReactNode;
  fullWidth?: boolean;
  icon?: Icon;
  withIcon?: boolean;
  outline?: boolean;
  size?: FormComponentSize;
  variant?: Variant;
  apiCallFinished?: boolean;
};

type ButtonElementProps = React.PropsWithoutRef<
  JSX.IntrinsicElements['button']
>;
type DivElementProps = React.PropsWithoutRef<JSX.IntrinsicElements['div']>;
type AnchorElementProps = React.PropsWithoutRef<JSX.IntrinsicElements['a']>;

export type Props = CommonProps &
  (ButtonElementProps | AnchorElementProps | DivElementProps);

const icons: Record<Icon, React.ReactNode> = {
  go: <Go />,
  send: <Send />,
  swords: <Swords />,
};

const sizeStyles: Record<FormComponentSize, string> = {
  xlarge: styles.sizeXlarge,
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const variantStyles: Record<Variant, string> = {
  green: styles.variantGreen,
  primary: styles.variantPrimary,
  secondary: styles.variantSecondary,
  invalid: styles.variantInvalid,
};

function Button(
  {
    children,
    className,
    fullWidth = false,
    icon,
    outline = false,
    size = 'medium',
    variant = 'primary',
    withIcon = false,
    apiCallFinished = false,
    ...props
  }: Props,
  ref: React.Ref<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>,
) {
  const commonProps = {
    ...props,
    className: classNames(
      apiCallFinished && styles.apiCallFinished,
      styles.button,
      fullWidth && styles.fullWidth,
      icon !== undefined && children === undefined && styles.iconOnly,
      sizeStyles[size],
      variantStyles[variant],
      outline && styles.outline,
      withIcon && styles.withIcon,
      !children && styles.noChildren,
      className,
    ),
    ref,
  };
  const intl = useIntl();

  const newChildren = (
    <>
      {icon !== undefined && icons[icon]}

      {children}
    </>
  );

  if ('href' in props) {
    return (
      <a {...(commonProps as AnchorElementProps)} href={props.href}>
        {newChildren}
      </a>
    );
  }

  return apiCallFinished ? (
    <div key='api-call-finished' {...(commonProps as DivElementProps)}>
      {intl.formatMessage({ defaultMessage: 'Loading' })}...
      <ActivityIndicator
        className={classNames(styles.activityIndicator)}
        spinnerWrapClassName={classNames(styles.buttonSpinner)}
      />
    </div>
  ) : (
    <button
      disabled={apiCallFinished}
      {...(commonProps as ButtonElementProps)}
      // eslint-disable-next-line react/button-has-type
      type={(props as ButtonElementProps).type}
    >
      {newChildren}
    </button>
  );
}

export default React.forwardRef(Button);
