import classNames from 'classnames';
import React from 'react';
import styles from './LandingProductCard.module.css';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    icon: any;
    title: any;
    subTitle: any;
  }
>;

export default function LandingProductCard({
  className,
  icon,
  title,
  subTitle,
  ...props
}: Props) {
  return (
    <div {...props} className={classNames(styles.productCard, className)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.title}>
        <p className={styles.main}>{title}</p>
        <p className={styles.subTitle}>{subTitle}</p>
      </div>
    </div>
  );
}
