import classNames from 'classnames';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { AnalyticsContext, LocalizationContext } from '../../contexts';
import { sendGAEvent } from '../../helpers';
import Select from '../Select';

type Props = {
  className?: string;
};

export default function LanguageSelector({ className }: Props) {
  const intl = useIntl();
  const { category } = useContext(AnalyticsContext);
  const { languageCode, setLanguageCode } = useContext(LocalizationContext);
  const options = [
    /* { label: 'العربي', value: 'ar' }, */
    { label: 'Türkçe', value: 'tr' },
    { label: 'English', value: 'en' },
  ];
  return (
    <Select
      className={classNames(className)}
      id='select-language-selector-select'
      label={intl.formatMessage({ defaultMessage: 'Language' })}
      onChange={(event) => {
        setLanguageCode(event.value as LanguageCode);

        sendGAEvent({
          category,
          event: 'Select Language',
          label: 'Language',
          value: event.value,
        });
      }}
      options={options}
      size='small'
      value={
        options.find((option) => option.value === languageCode)?.value || ''
      }
    />
  );
}
