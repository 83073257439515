import classNames from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import Avatar from '../Avatar';
import Countdown from '../Countdown';
import GACoin from '../GACoin';
import LadderTournamentStatusDisplay from '../LadderTournamentStatusDisplay';
import styles from './LadderTournamentCard.module.css';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    ladder: Ladder;
  }
>;

export default function LadderTournamentCard({
  className,
  ladder,
  ...props
}: Props) {
  const { data: game } = useSWR<Game>(`/games/${ladder.game}/`);
  const [isCountdownVisible, setIsCountdownVisible] = useState<boolean>(true);

  return game === undefined ? null : (
    <div
      {...props}
      className={classNames(styles.ladderTournamentCard, className)}
    >
      <Link href={`/tournaments/ladder/?id=${ladder.id}`}>
        <a className={styles.link}>
          <div
            className={styles.header}
            style={{ backgroundImage: `url(${game.image})` }}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.game}>
                <Avatar image={game.image} size={36} />
                <p className={styles.name}>{game.name}</p>
              </div>

              <div className={styles.status}>
                <LadderTournamentStatusDisplay ladderStatus={ladder.status} />
              </div>
            </div>

            {ladder.status !== 'FINISHED' && isCountdownVisible && (
              <div className={styles.countdown}>
                <p className={styles.remaining}>
                  <FormattedMessage defaultMessage='Remaining Time' />
                </p>
                <Countdown
                  className={styles.time}
                  date={
                    ladder.status === 'REGISTRATION' ||
                    ladder.status === 'FUTURE'
                      ? ladder.startsAt
                      : ladder.endsAt
                  }
                  onComplete={() => setIsCountdownVisible(false)}
                />
              </div>
            )}
          </div>
          <div className={styles.body}>
            <div className={styles.detailHeader}>
              <p className={styles.title}>{ladder.name}</p>
            </div>
            <div className={styles.reward}>
              <GACoin className={styles.gaCoin} />
              <p>
                <FormattedMessage
                  defaultMessage='{totalReward} Total Reward'
                  values={{
                    totalReward: ladder.totalReward,
                  }}
                />
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <p className={styles.muted}>
              <FormattedMessage defaultMessage='Starting Date' />:
            </p>
            <div className={styles.info}>
              <p>{dayjs(ladder.startsAt).format('DD MMM, HH:mm')}</p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
}
