import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import firebase from 'firebase/app';
import dayjs from 'dayjs';
import {
  AnalyticsContext,
  AuthAndApiContext,
  RegionContext,
} from '../../contexts';
import { sendGAEvent } from '../../helpers';
import { ReactComponent as Card } from '../DepositPaymentMethod/card.svg';
import Errors from '../Errors';
import FormGroup from '../FormGroup';
// import IcrypexVerifier from '../IcrypexVerifier';
import RadioInput from '../RadioInput';
import TextInput from '../TextInput';
// import Icrypex from './icrypex.png';
import { ReactComponent as Info } from './info.svg';
// import N11 from './n11.png';
import Papara from './papara.png';
import Gamerarena from './GamerArenaLogo.png';
import { ReactComponent as PayPal } from './paypal.svg';
import styles from './WithdrawPaymentMethod.module.css';
import LoginSocialButtons from '../LoginSocialButtons';
import Button from '../Button';
import { ReactComponent as Down } from './down.svg';
import { ReactComponent as Up } from './up.svg';

type PaparaUserInfo = {
  paparaId: string | null;
  tcIdNumber: string | null;
};

type IcrypexUserInfo = {
  email: string | null;
  phone: string | null;
  firstName: string | null;
  lastName: string | null;
  identity: string | null;
  isVerified: boolean | null;
  smsPassword: string | null;
};

type IcrypexResponse = {
  returnCode: string | null;
  returnMessage: string | null;
  gaValidation: Record<string, Array<string>> | null;
};

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    errors: Record<string, Array<string>>;
    paparaUserInfo: PaparaUserInfo;
    icrypexUserInfo: IcrypexUserInfo;
    icrypexResponse: IcrypexResponse;
    paymentMethod: PaymentProvider | null;
    setPaparaUserInfo: (paymentMethod: Props['paparaUserInfo']) => void;
    setIcrypexUserInfo: (paymentMethod: Props['icrypexUserInfo']) => void;
    setIcrypexResponse: (paymentMethod: Props['icrypexResponse']) => void;
    setPaymentMethod: (paymentMethod: Props['paymentMethod']) => void;
  }
>;

export default function WithdrawPaymentMethod({
  className,
  errors,
  paparaUserInfo,
  // icrypexUserInfo,
  // icrypexResponse,
  paymentMethod,
  setPaparaUserInfo,
  // setIcrypexUserInfo,
  // setIcrypexResponse,
  setPaymentMethod,
  ...props
}: Props) {
  const intl = useIntl();
  const { user } = useContext(AuthAndApiContext);
  const { category } = useContext(AnalyticsContext);
  const { region } = useContext(RegionContext);
  const { data: wallet } = useSWR<WalletDetail>('/wallet/wirecard/', {
    shouldRetryOnError: false,
  });
  const { data: papara } = useSWR<PaparaWallet>('/wallet/papara/', {
    shouldRetryOnError: false,
  });
  const singleWidthdrawMethod = region.withdrawProviders.length === 1;

  const [isGaApp, setisGaApp] = useState(false);
  const [isGA, setIsGA] = useState(false);
  const [loginResponse, setLoginResponse] = useState({
    nickname: '',
    wallet: '',
  });

  const [tranStats, setTranStats] = useState([
    {
      _id: '',
      amount: {
        $numberDecimal: '',
      },
      status: 0,
      transaction: 0,
      channel: 0,
      date: '',
    },
  ]);

  const [sliderValue, setSliderValue] = useState(Number(user.balance));

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(e.target.value));
  };

  useEffect(() => {
    if (paymentMethod !== null) {
      sendGAEvent({
        category,
        event: 'Set Provider',
        label: 'Withdraw',
        value: paymentMethod || '',
      });
    }
  }, [paymentMethod]);

  const secondApp = firebase.app('gaapp');
  const secondAuth = secondApp.auth();

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    setIsGA(true);
    return secondAuth.signInWithPopup(provider);
  };

  // Apple ile oturum açma işlemi için
  const signInWithApple = () => {
    const appleProvider = new firebase.auth.OAuthProvider('apple.com');
    setIsGA(true);
    return secondAuth.signInWithPopup(appleProvider);
  };

  function userWallet(token: string) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_MOBILE_URL}api/profile/myWallet`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        if (response.status === 401) {
          secondAuth.signOut().then(() => {
            // setLoginResponse(null);
            // setWalletLogin(null);
            localStorage.clear();
          });
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setLoginResponse((prev) => ({
          ...prev,
          wallet: res?.[0]?.count?.$numberDecimal,
        }));
        console.log('setLoginResponsekısmı', res);
      })
      .catch((error) => console.log('error', error));
  }

  const localStorageBasicTokenKey = process.env
    .NEXT_PUBLIC_GAMERARENA_LOCAL_STORAGE_BASIC_TOKEN_KEY as string;
  const tokens = window.localStorage.getItem(
    localStorageBasicTokenKey,
  ) as string;

  function socialLoginWallet(token: string) {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    const raw = JSON.stringify({
      token,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/transaction/connectAccount`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        console.log(res);
        setLoginResponse(res);
        userWallet(res?.token);
        window.localStorage.setItem('Token', res?.token);
        getTransactionStats();
      })
      .catch((error) => console.log('error', error));
  }

  const tokenSpor = window.localStorage.getItem('Token') as string;

  function getTransactionStats() {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    myHeaders.append('x-access-token', tokenSpor);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_MOBILE_URL}api/profile/getTransactionStats`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        setTranStats(res);
        console.log(tranStats);
      })
      .catch((error) => console.log('error', error));
  }

  function toMobile() {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', tokens);
    const raw = JSON.stringify({
      token: tokenSpor,
      amount: sliderValue,
    });
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/transaction/toMobile`,
      requestOptions,
    )
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          return Promise.resolve(response.json()); // This will end up in SUCCESS part
        }
        const responseInJson = await Promise.resolve(response.json());
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        console.log('ok', res);
        window.location.replace('https://gamerarena.com/wallet');
      })
      .catch((error) => console.log('error', error));
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isGA) {
      const unsubscribe = secondAuth.onAuthStateChanged((users) => {
        console.log('usewr2', users);
        // auth.signOut()
        if (users) {
          users.getIdToken().then((token) => {
            console.log('ali veli', token);

            socialLoginWallet(token);
          });
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [isGA]);

  return region === undefined ? null : (
    <section
      {...props}
      className={classNames(styles.withdrawPaymentMethod, className)}
    >
      <div className={styles.header}>
        <h4>
          <FormattedMessage defaultMessage='How You Get Paid' />
        </h4>
      </div>

      <div
        className={classNames(
          styles.body,
          singleWidthdrawMethod && styles.singleWidthdrawMethodBody,
        )}
      >
        {wallet !== undefined &&
          region.withdrawProviders.includes('wirecard_emoney') && (
            <div className={styles.wirecard}>
              <RadioInput
                checked={paymentMethod === 'wirecard_emoney'}
                className={styles.radio}
                id='wirecard_emoney'
                label={
                  <div className={styles.content}>
                    <Card />

                    <div className={styles.info}>
                      <p className={styles.muted}>
                        <FormattedMessage defaultMessage='Default Method' />
                      </p>

                      <p>IBAN {/* {wallet.iban} */}</p>
                    </div>
                  </div>
                }
                onChange={(e) =>
                  setPaymentMethod(e.target.value as PaymentProvider)
                }
                required
                value='wirecard_emoney'
              />

              <div className={styles.disclaimer}>
                <Info />

                <p>
                  <FormattedMessage defaultMessage='If you want to change your bank account, please contact us.' />
                </p>
              </div>
            </div>
          )}

        {region.withdrawProviders.includes('papara_masspayment') && (
          <div>
            <RadioInput
              checked={paymentMethod === 'papara_masspayment'}
              className={styles.radio}
              id='papara_masspayment'
              label={
                <div className={styles.content}>
                  <img
                    alt='papara'
                    className={styles.papara}
                    src={Papara.src}
                  />
                </div>
              }
              onChange={(e) => {
                setPaymentMethod(e.target.value as PaymentProvider);
                setisGaApp(false);
              }}
              required
              value='papara_masspayment'
            />

            {paymentMethod === 'papara_masspayment' && (
              <>
                <div className={styles.paparaInputs}>
                  <FormGroup error={errors.tckn}>
                    <TextInput
                      disabled={papara !== undefined && papara.isVerified}
                      id='papara-wallet-tc-id-number'
                      label={intl.formatMessage({
                        defaultMessage: 'TC ID Number',
                      })}
                      name='tcIdNumber'
                      onChange={(e) =>
                        setPaparaUserInfo({
                          ...paparaUserInfo,
                          tcIdNumber: e.target.value,
                        })
                      }
                      required={paymentMethod === 'papara_masspayment'}
                      size='large'
                      value={paparaUserInfo.tcIdNumber || ''}
                    />
                  </FormGroup>

                  <FormGroup error={errors.paparaId}>
                    <TextInput
                      disabled={papara !== undefined && papara.isVerified}
                      id='papara-wallet-papara-id'
                      label={intl.formatMessage({
                        defaultMessage: 'Papara Id',
                      })}
                      name='paparaId'
                      onChange={(e) =>
                        setPaparaUserInfo({
                          ...paparaUserInfo,
                          paparaId: e.target.value,
                        })
                      }
                      required={paymentMethod === 'papara_masspayment'}
                      size='large'
                      value={paparaUserInfo.paparaId || ''}
                    />
                  </FormGroup>
                </div>

                {papara !== undefined && papara.isVerified && (
                  <div className={styles.disclaimer}>
                    <Info />

                    <p>
                      <FormattedMessage defaultMessage='If you want to change your Papara account, please contact us.' />
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {/* GAMER ARENA APP KISMI  */}
        <div>
          <RadioInput
            checked={isGaApp}
            className={styles.radio}
            id='gamerarenaPayment'
            label={
              <div className={styles.content}>
                <img alt='gamerarena' src={Gamerarena.src} />
              </div>
            }
            onChange={() => {
              setisGaApp(true);
              setPaymentMethod(null);
            }}
            required
            value='gamerarena_payment'
          />
        </div>

        {isGaApp && loginResponse?.nickname.length === 0 && (
          <>
            <LoginSocialButtons
              onClick={() => signInWithGoogle()}
              socialType='google'
              text={<FormattedMessage defaultMessage='Continue with Google' />}
            />

            <LoginSocialButtons
              onClick={() => signInWithApple()}
              socialType='apple'
              text={<FormattedMessage defaultMessage='Continue with Apple' />}
            />
          </>
        )}
        {loginResponse?.nickname.length > 0 && (
          <>
            {/* GA APP */}
            <div
              style={{
                background:
                  'linear-gradient(112.1deg, #EB2A44 17.84%, #8C49E2 103.4%)',
                borderRadius: '6px',
                height: '140px',
                width: '100%',
              }}
            >
              <div style={{ paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <p
                    style={{
                      fontSize: '15px',
                      color: '#FFFFFF',
                      padding: 1,
                    }}
                  >
                    GAMER ARENA APP
                  </p>
                </div>
                <div
                  style={{
                    padding: '5%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          opacity: 0.7,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                      >
                        <FormattedMessage defaultMessage='Username' /> : @
                        {loginResponse?.nickname}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: 0, paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '5%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          fontSize: '20px',
                          color: '#FFFFFF',
                          padding: 1,
                        }}
                      >
                        {Number(loginResponse?.wallet).toFixed(2) ?? '-'} GA
                        Coin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GA PLATFORM */}
            <div
              style={{
                background:
                  'linear-gradient(112.1deg, #8C49E2 17.84%, #EB2A44 103.4%)',
                borderRadius: '6px',
                height: '140px',
                width: '100%',
              }}
            >
              <div style={{ paddingBottom: 0 }}>
                <div style={{ paddingLeft: '5%', paddingTop: '2%' }}>
                  <p
                    style={{
                      fontSize: '15px',
                      color: '#FFFFFF',
                      padding: 1,
                    }}
                  >
                    GAMER ARENA PLATFORM
                  </p>
                </div>
                <div style={{ padding: '5%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          opacity: 0.7,
                          fontSize: '14px',
                          color: '#FFFFFF',
                        }}
                      >
                        <FormattedMessage defaultMessage='Username' /> : @
                        {user?.fullName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: 0, paddingBottom: 0 }}>
                <div style={{ padding: '5%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ justifyContent: 'flex-start' }}>
                      <p
                        style={{
                          fontSize: '20px',
                          color: '#FFFFFF',
                          padding: 1,
                        }}
                      >
                        {user.balance} GA Coin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: '5%' }}>
              <p
                style={{
                  fontSize: '15px',
                  color: '#FFFFFF',
                  padding: 1,
                }}
              >
                <FormattedMessage defaultMessage='Platform to App' />
              </p>
            </div>
            <div>
              <input
                id='mySlider'
                max={Number(user.balance)}
                min='0'
                onChange={handleSliderChange}
                style={{
                  width: '100%',
                  height: '5px',
                  background: '#ccc',
                  outline: 'none',
                  opacity: '0.7',
                  transition: 'opacity .2s',
                }}
                type='range'
                value={sliderValue}
              />
              <span
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  display: 'block',
                }}
              >
                {sliderValue}
              </span>
            </div>
          </>
        )}

        {/* ----- */}

        {/* {region.withdrawProviders.includes('icrypex') && (
        <div>
          <RadioInput
            checked={paymentMethod === 'icrypex'}
            className={styles.radio}
            id="icrypex"
            label={(
              <div className={styles.content}>
                <img alt="icrypex" className={styles.icrypex} loading="lazy" src={Icrypex.src}/>
              </div>
            )}
            onChange={(e) => setPaymentMethod(e.target.value as PaymentProvider)}
            required
            value="icrypex"
          />

          {paymentMethod === 'icrypex' && (
            <IcrypexVerifier
              errors={errors}
              gaCoinAmount={0}
              icrypexResponse={icrypexResponse}
              icrypexUserInfo={icrypexUserInfo}
              paymentMethod={paymentMethod}
              setIcrypexResponse={setIcrypexResponse}
              setIcrypexUserInfo={setIcrypexUserInfo}
            />
          )}
        </div>
        )} */}

        {/* {region.withdrawProviders.includes('n11') && (
          <RadioInput
            checked={paymentMethod === 'n11'}
            className={styles.radio}
            id='n11'
            label={
              <div className={styles.content}>
                <img alt='n11' className={styles.n11} src={N11.src} />
              </div>
            }
            onChange={(e) =>
              setPaymentMethod(e.target.value as PaymentProvider)
            }
            required
            value='n11'
          />
        )} */}

        {region.withdrawProviders.includes('paypal_payments') && (
          <RadioInput
            checked={paymentMethod === 'paypal_payments'}
            className={classNames(styles.radio, styles.paypal)}
            id='paypal_payments'
            label={<PayPal />}
            onChange={(e) =>
              setPaymentMethod(e.target.value as PaymentProvider)
            }
            required
            value='paypal_payments'
          />
        )}

        <Errors errors={errors.provider} />
      </div>
      {loginResponse?.nickname.length > 0 && (
        <Button
          onClick={() => toMobile()}
          size='large'
          type='submit'
          variant='green'
        >
          <FormattedMessage defaultMessage='Withdraw' />
        </Button>
      )}
      {loginResponse?.nickname.length > 0 && (
        <>
          <FormattedMessage defaultMessage='Your Withdrawal Requests in Progress' />
          {tranStats.map((item, indeks) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.item} key={indeks}>
              <div style={{ width: 25, height: 25 }}>
                {item.transaction === 0 ? <Down /> : <Up />}
              </div>

              <div className={styles.details}>
                <p className={styles.date}>
                  {dayjs(item.date).format('D MMMM')}
                </p>

                {/* <p className={styles.kind}>{transactionKinds[t.kind]}</p> */}
              </div>

              <div className={styles.amount}>
                {item.transaction === 0 ? '-' : '+'}
                {item.amount.$numberDecimal}{' '}
                <FormattedMessage defaultMessage='GA Coins' />
              </div>
            </div>
          ))}
        </>
      )}
    </section>
  );
}
