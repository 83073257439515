import dynamic from 'next/dynamic';
import React from 'react';

const DynamicSignUpOrSignInWithNoSSR = dynamic(
  () => import('./SignUpOrSigning'),
  {
    ssr: false,
  },
);

export default () => <DynamicSignUpOrSignInWithNoSSR />;
