import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './ActivityIndicator.module.css';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    takeOver?: boolean;
    flex?: boolean;
    spinnerWrapClassName?: string;
  }
>;

export default function ActivityIndicator({
  className,
  spinnerWrapClassName,
  flex,
  takeOver,
  ...props
}: Props) {
  /* TODO: #539 @ayhan element <Component> </body> kapanışından hemen önce eklenemediği 
  için header'ın üzerine binmesi için yapıldı. İlerde componentin direk body 
  kapanışından hemen önce eklenmesi sağlanmalı. */
  useEffect(() => {
    const headerAbsolutelyNotNullElement =
      window.document.querySelector('header')!;
    if (takeOver) {
      if (headerAbsolutelyNotNullElement !== null) {
        headerAbsolutelyNotNullElement.style.zIndex = '0';
      }
    }

    return () => {
      if (headerAbsolutelyNotNullElement) {
        headerAbsolutelyNotNullElement.removeAttribute('style');
      }
    };
  }, [takeOver]);

  return (
    <div
      {...props}
      className={classNames(
        styles.activityIndicator,
        flex && styles.flex,
        takeOver && styles.takeOver,
        className,
      )}
    >
      <div className={classNames(styles.wrapper, spinnerWrapClassName)}>
        <span className={styles.inner} />
      </div>
    </div>
  );
}
