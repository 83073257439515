import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { entryFeeChoices } from '../../constants';
import {
  AnalyticsContext,
  AuthAndApiContext,
  BreakpointContext,
  FilterSortContext,
  RegionContext,
} from '../../contexts';
import { createGtag, hydrateGameMode, sendGAEvent } from '../../helpers';
import {
  FormInput,
  FormSelect,
  CommonError,
  yupCustom as yup,
} from '../Form/index';
import Button from '../Button';
import GameModeTooltip from '../GameModeTooltip';
import Modal from '../Modal';
import QuickDuelTournamentInfo from '../QuickDuelTournamentInfo';
import RequiredAccountFieldDisplay from '../RequiredAccountFieldDisplay';
/* import Tippy from '../Tippy'; */
import styles from './QuickDuel.module.css';
import ActivityIndicator from '../ActivityIndicator';
/* import { ReactComponent as Reward } from './reward.svg'; */

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['section']> & {
  className?: string;
};

type FormType = {
  entryFee: number;
  game: number | null;
  gameMode: number | null;
  platform: number | null;
  requiredAccountField?: string;
};

const defaultValues: FormType = {
  entryFee: 20,
  game: null,
  gameMode: null,
  platform: null,
  requiredAccountField: '',
};

const QuickDuel = ({ className, ...props }: Props) => {
  const router = useRouter();
  const intl = useIntl();
  const { api, user } = useContext(AuthAndApiContext);
  const { region } = useContext(RegionContext);
  const { breakpoint } = useContext(BreakpointContext);
  const { category } = useContext(AnalyticsContext);
  const { selectedDuelFilters, setSelectedDuelFilters } =
    useContext(FilterSortContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [serverError, setServerError] = useState<any>(null);
  const [isModalActionDisabled, setIsModalActionDisabled] =
    useState<boolean>(false);

  let hydratedGameMode: any = null;
  const validationSchema = yup.object({
    entryFee: yup.number(),
    game: yup.number().nullable(true).required(),
    gameMode: yup.number().nullable(true).required(),
    platform: yup.number().nullable(true).required(),
    requiredAccountField: yup.lazy((value: any) => {
      if (
        value !== null &&
        user![hydratedGameMode?.requiredAccountField] === null
      ) {
        return yup.string().required();
      }

      return yup.string();
    }),
  });
  // @ts-ignore
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    control,
    unregister,
    watch,
    setError,
  }: UseFormReturn<FormType> = methods;

  const watchGame = watch('game');
  const watchPlatform = watch('platform');
  const watchGameMode = watch('gameMode');

  const fetcher = (url: any) => fetch(url).then((r) => r.json());

  const { data: games } = useSWR<Game[]>(
    `/games/?is_active=true&region=${region.id}`,
  );

  const { data: platforms } = useSWR<Platform[]>(
    watchGame === null
      ? null
      : // eslint-disable-next-line max-len
        `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/platforms/?is_active=true&game=${watchGame}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('platform') === null && data?.length === 1) {
          setValue('platform', data[0].id, {
            shouldValidate: true,
          });

          setSelectedDuelFilters({
            ...selectedDuelFilters,
            platform: data[0].id,
          });
        }
      },
    },
  );

  const { data: gameModes } = useSWR<GameMode[]>(
    watchGame === null || watchPlatform === null
      ? null
      : // eslint-disable-next-line max-len
        `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/game_modes/?is_active=true&game=${watchGame}&platform=${watchPlatform}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('gameMode') === null && data?.length === 1) {
          setValue('gameMode', data[0].id, {
            shouldValidate: true,
          });

          setSelectedDuelFilters({
            ...selectedDuelFilters,
            gameMode: data[0].id,
          });
        }
      },
    },
  );

  const { data: gameMode } = useSWR<GameMode>(
    watchGameMode === null
      ? null
      : `/games/game_modes/${watchGameMode}/?region=${region.id}`,
  );

  hydratedGameMode = useMemo<HydratedGameMode | null>(() => {
    if (gameMode === undefined) return null;

    return hydrateGameMode(gameMode);
  }, [gameMode]);

  /* const Tooltip = () => (
    <Tippy content='aaa'>
      <Reward style={{ width: '16px', height: '16px' }} />
    </Tippy>
  ); */

  useEffect(() => {
    if (selectedDuelFilters.game === null && games?.length === 1) {
      setValue('game', games[0].id);
    }

    if (selectedDuelFilters.game !== null) {
      setValue('game', selectedDuelFilters.game);
    }

    if (selectedDuelFilters.game === 110 || selectedDuelFilters.game === 112) {
      setValue('entryFee', 0);
    } else {
      setValue('entryFee', defaultValues.entryFee);
    }
  }, [games, selectedDuelFilters.game]);

  useEffect(() => {
    if (selectedDuelFilters.platform !== null) {
      setValue('platform', selectedDuelFilters.platform);
    }
  }, [selectedDuelFilters.platform]);

  useEffect(() => {
    if (selectedDuelFilters.gameMode !== null) {
      setValue('gameMode', selectedDuelFilters.gameMode);
    }
  }, [selectedDuelFilters.gameMode]);

  const gameRules = useMemo(
    () =>
      selectedDuelFilters.gameMode === null ? null : (
        <div
          className={classNames(
            ['xl', 'xxl'].includes(breakpoint) ? styles.info : styles.subInfo,
          )}
        >
          <GameModeTooltip gameModeId={selectedDuelFilters.gameMode} />
        </div>
      ),
    [breakpoint, selectedDuelFilters.gameMode],
  );

  const handleFormSubmit = async (/* data: FormType */) => {
    sendGAEvent({
      category: 'Arena',
      event: 'Click Quick Duel CTA',
      label: 'Quick Duel',
      value: getValues('entryFee'),
    });
    setIsModalOpen(true);
  };

  return games === undefined || user === null ? null : (
    <>
      <Modal
        actions={
          <>
            <Button
              disabled={isModalActionDisabled}
              onClick={() => setIsModalOpen(false)}
              type='button'
              variant='secondary'
            >
              <FormattedMessage defaultMessage='Cancel' />
            </Button>

            <Button
              disabled={isModalActionDisabled}
              onClick={async () => {
                // this is here only as a type guard
                if (hydratedGameMode === null) return;

                setIsModalActionDisabled(true);

                if (getValues('requiredAccountField') !== null) {
                  const requiredAccountFieldResponse = await api.patch(
                    '/users/me/',
                    {
                      [hydratedGameMode.requiredAccountField]: getValues(
                        'requiredAccountField',
                      ),
                    },
                  );

                  const requiredAccountFieldResponseJson =
                    await requiredAccountFieldResponse.json();

                  if (requiredAccountFieldResponse.ok) {
                    await mutate(
                      '/users/me/',
                      requiredAccountFieldResponseJson,
                    );
                  } else {
                    return;
                  }
                }

                const payload = getValues();

                delete payload.requiredAccountField;

                const response = await api.post(
                  '/duels/get_or_create/',
                  payload,
                );
                const responseJson = await response.json();

                if (response.ok) {
                  sendGAEvent({
                    category,
                    event: 'Click Find Duel',
                    label: 'Quick Duel',
                  });

                  await router.push(`/duels/duel?id=${responseJson.id}`);
                } else {
                  setServerError(responseJson);

                  if (responseJson?.entryFee) {
                    setError('entryFee', {
                      type: 'server',
                      message: responseJson?.entryFee[0],
                    });
                  }
                }

                setIsModalOpen(false);
                setIsModalActionDisabled(false);
              }}
              type='button'
            >
              <FormattedMessage defaultMessage='Find Duel' />
            </Button>
          </>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <FormattedMessage defaultMessage="You will join a duel based on your rating or create a new duel if there aren't any open. Continue?" />
      </Modal>

      {isSubmitting && <ActivityIndicator takeOver />}

      <section
        {...props}
        className={classNames(className, styles.quickDuel)}
        data-cy='quickDuel'
      >
        <div className={styles.header}>
          <h4>
            <FormattedMessage defaultMessage='Quick Duel' />
          </h4>
        </div>

        <div className={styles.content}>
          <div className={styles.description}>
            <FormattedMessage defaultMessage='Quickly join or create the optimum duel for you.' />
          </div>

          <form
            className={styles.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <CommonError errors={serverError} />

            <fieldset
              className={classNames(
                styles.fieldset,
                hydratedGameMode !== null &&
                  user[hydratedGameMode.requiredAccountField] === null &&
                  styles.accountNeeded,
              )}
            >
              <div
                className={classNames(
                  styles.inputs,
                  hydratedGameMode !== null &&
                    user[hydratedGameMode.requiredAccountField] === null &&
                    styles.hydrated,
                )}
              >
                <FormSelect
                  control={control}
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Game' })}
                  name='game'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setValue('platform', null);
                    setValue('gameMode', null);

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      game: value,
                      gameMode: null,
                      platform: null,
                    });

                    if (value === 110 || value === 112) {
                      setValue('entryFee', 0);

                      setSelectedDuelFilters({
                        ...selectedDuelFilters,
                        game: value,
                        entryFee: 0,
                      });
                    }

                    sendGAEvent({
                      category,
                      event: 'Select Game',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={games.map((option: any) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />
                <FormSelect
                  control={control}
                  disabled={platforms === undefined}
                  errorobj={errors}
                  label={intl.formatMessage({ defaultMessage: 'Platform' })}
                  name='platform'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setValue('gameMode', null);

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      gameMode: null,
                      platform: value,
                    });

                    sendGAEvent({
                      category,
                      event: 'Select Platform',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={platforms?.map((option: any) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <FormSelect
                  control={control}
                  disabled={gameModes === undefined}
                  errorobj={errors}
                  label={intl.formatMessage({
                    defaultMessage: 'Game Mode',
                  })}
                  name='gameMode'
                  onChange={(opt: any) => {
                    const { value, label } = opt;

                    setSelectedDuelFilters({
                      ...selectedDuelFilters,
                      gameMode: value,
                    });

                    sendGAEvent({
                      category,
                      event: 'Select Game Mode',
                      label: 'Quick Duel',
                      value: label,
                    });
                  }}
                  options={gameModes?.map((option: any) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                  size='large'
                  unregister={unregister}
                />

                <FormInput
                  control={control}
                  errorobj={errors}
                  isVisible={
                    hydratedGameMode !== null &&
                    user![hydratedGameMode?.requiredAccountField] === null
                  }
                  label={
                    <RequiredAccountFieldDisplay
                      field={hydratedGameMode?.requiredAccountFieldStatus}
                    />
                  }
                  name='requiredAccountField'
                  size='large'
                  unregister={unregister}
                />

                <FormSelect
                  control={control}
                  disabled={
                    getValues('game') === 110 || getValues('game') === 112
                  }
                  errorobj={errors}
                  label={intl.formatMessage({
                    defaultMessage: 'Entry Fee',
                  })}
                  name='entryFee'
                  onChange={(opt: any) => {
                    const { value } = opt;

                    sendGAEvent({
                      category,
                      event: 'Select Entry Fee',
                      label: 'Quick Duel',
                      value,
                    });
                  }}
                  options={entryFeeChoices?.map((option: any) => ({
                    label: `${option} GA Coin`,
                    value: option,
                    disabled:
                      (getValues('game') === 110 ||
                        getValues('game') === 112) &&
                      option !== 0,
                  }))}
                  size='large'
                  unregister={unregister}
                />
              </div>

              <div className={styles.sub}>
                {!['xl', 'xxl'].includes(breakpoint) && (
                  <div className={styles.subDetail}>
                    {gameRules}
                    <QuickDuelTournamentInfo />
                  </div>
                )}

                <div className={styles.submit}>
                  <Button
                    className={styles.submitButton}
                    disabled={isSubmitting}
                    size='large'
                    type='submit'
                  >
                    <FormattedMessage defaultMessage='Find Duel' />
                  </Button>
                </div>
              </div>
            </fieldset>
          </form>

          <div className={styles.subNav}>
            {['xl', 'xxl'].includes(breakpoint) && (
              <div className={styles.subDetail}>
                {gameRules}

                <QuickDuelTournamentInfo />
              </div>
            )}

            <div className={styles.redirect}>
              <Link
                href={{
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    duelCreation: 'true',
                  },
                }}
                passHref
              >
                <a
                  data-gtag={createGtag({
                    category,
                    event: 'Click Find Duel',
                    label: 'Open Custom Duel Modal',
                  })}
                >
                  <FormattedMessage defaultMessage='or you can create custom duel' />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuickDuel;
